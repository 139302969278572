import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import SpinnerLoding from "../../component/SpinnerLoding/SpinnerLoding";

const PrivateRoute = ({ children }) => {
  const userLogn = JSON.parse(sessionStorage.getItem("token"));
  const result = useSelector((state) => state.login);

  return userLogn || result.status ? children : <Navigate to={"/Login"} />;
};

export default PrivateRoute;
