import React from "react";
import Chart from "react-apexcharts";

const ChartArea = ({ valueHight }) => {
  let arraydata = [1991, 1992, 1993, 1994, 1995, 1996];
  let arrayvalue = [30, 40, 45, 50, 20, 10];

  const data = {
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },

      chart: {
        background: "none",
        id: "basic-bar",
        fill: {
          type: "gradient",
        },
      },
      // colors: ['#E83C33', '#E83C33'],
      xaxis: {
        categories: arraydata,
      },
    },
    series: [
      {
        name: "series-1",
        data: arrayvalue,
      },
    ],
  };

  return (
    <Chart
      options={data.options}
      series={data.series}
      type="area"
      height={valueHight}
      width="100%"
    />
  );
};
export default ChartArea;
