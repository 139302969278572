import axios from "axios";

export let ECOMMERCE_API = `https://ecommerce.vodoglobal.com`
export let VODOGLOBAL_ACOUNTING_API = `https://accounting.vodoglobal.com`
const secure_business = JSON.parse(sessionStorage.getItem("secure_key"))
// export let ECOMMERCE_API = `https://testecommerce.vodoglobal.com`
// export let VODOGLOBAL_ACOUNTING_API = `https://test.vodoglobal.com`
const header = {
    headers: {
        apikey: 'efe2db322a53',
        'secure-business-key': secure_business

    }
}

export const inventories = (type, pageNumber) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_API}/api/inventories?pagination=${"inventories" === type}&page=${pageNumber}`, {
            headers: {
                apikey: '5f28583f26a1a',
                apiconnection: "appmobile",
                'secure-business-key': secure_business
            }
        })
        dispatch({ type: type, payload: res.data });
    }

}
export const products = (key, lang) => {
    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_API}/api/products?keyword=${key}`, {
            headers: {
                apikey: '5f28583f26a1a',
                apiconnection: "appmobile",
                'secure-business-key': secure_business,
                "Accept-Language": lang
            }
        })
        dispatch({ type: "products", payload: res.data });
    }

}
export const InvocesTotalChart = (data, type) => {

    return async (dispatch) => {

        const res = await axios.get(`${VODOGLOBAL_ACOUNTING_API}/api/journal_totals?from_date=${data && data.from_date}&to_date=${data && data.to_date}&date_filter_type=${data && data.date_filter_type}&day=${data && data.day}&month=${data && data.month}&year=${data && data.year}&type=${type}`, header)
        dispatch({ type: "InvocesTotalChart", InvocesTotalChart: res.data });
    }
}
export const InvocesTotal = (data, type) => {

    return async (dispatch) => {
        const res = await axios.get(`${VODOGLOBAL_ACOUNTING_API}/api/journal_totals?limit=${data && data.limit}&pagination=${data && data.pagination}&page=${data && data.page}&from_date=${data && data.from_date}&to_date=${data && data.to_date}&date_filter_type=${data && data.date_filter_type}&day=${data && data.day}&month=${data && data.month}&year=${data && data.year}&data_type=${data && data.data_type}&type=${type}`, header)


        dispatch({ type: "INVOCETOTAL", InvocesTotal: res.data });
    }
}

export const account_totals = (Month, year, type, page) => {

    return async (dispatch) => {

        const res = await axios.get(`${VODOGLOBAL_ACOUNTING_API}/api/account_totals`, header)

        dispatch({ type: "account_totals", account_totals: res.data });
    }
}

export const business_users_chart = (actiontype, data, pagination = false, data_type = "all") => {
    return async (dispatch) => {
        const res = await axios.get(`${VODOGLOBAL_ACOUNTING_API}/api/business_users/charts?
            page=${data && data.page}
            &user_type=${data && data.user_type}
            &pagination=${pagination}
            &year=${data && data.year}
            &month=${data && data.month}
            &day=${data && data.day}
            &date_filter_type=${data && data.date_filter_type}
            &to_date=${data && data.to_date}
            &application_type=bi&
            data_type=${data_type}
            &email=${data && data.email}
            &phone=${data && data.phone}
            &address1=${data && data.address1}
            &address2=${data && data.address2}
            &country_id=${data && data.country_id}
            &vat_number=${data && data.vat_number}
            &currency=${data && data.currency}
            &from_date=${data && data.from_date}
            &city=${data && data.city}
            &district=${data && data.district}
            &longitude=${data && data.longitude}
            &latitude=${data && data.latitude}
            &state=${data && data.state}
            &postcode=${data && data.postcode}
            &company_name=${data && data.company_name}
            &business_identifier=${data && data.business_identifier}
            &billing_address=${data && data.billing_address}
            &delivery_address=${data && data.delivery_address}
            &credit_limit=${data && data.credit_limit}
            &status=${data && data.status}
            &limit=${data && data.limit}
            `, header)

        dispatch({ type: actiontype, payload: res.data });
    }

}
export const business_users_Totals = (data) => {

    return async (dispatch) => {

        const res = await axios.get(`${VODOGLOBAL_ACOUNTING_API}/api/business_users/charts/count?
        page=${data && data.page}
        &user_type=${data && data.user_type}
        &pagination=false
        &year=${data && data.year}
        &month=${data && data.month}
        &day=${data && data.day}
        &date_filter_type=${data && data.date_filter_type}
        &to_date=${data && data.to_date}
        &application_type=bi&
        &email=${data && data.email}
        &phone=${data && data.phone}
        &address1=${data && data.address1}
        &address2=${data && data.address2}
        &country_id=${data && data.country_id}
        &vat_number=${data && data.vat_number}
        &currency=${data && data.currency}
        &from_date=${data && data.from_date}
        &city=${data && data.city}
        &district=${data && data.district}
        &longitude=${data && data.longitude}
        &latitude=${data && data.latitude}
        &state=${data && data.state}
        &postcode=${data && data.postcode}
        &company_name=${data && data.company_name}
        &business_identifier=${data && data.business_identifier}
        &billing_address=${data && data.billing_address}
        &delivery_address=${data && data.delivery_address}
        &credit_limit=${data && data.credit_limit}
        &status=${data && data.status}
        &limit=${data && data.limit}
      
      
        `, header)

        dispatch({ type: "business_users_Totals", payload: res.data });
    }
}
export const business_users_all_data = (year, month, day, type) => {

    return async (dispatch) => {

        const res = await axios.get(`${VODOGLOBAL_ACOUNTING_API}/api/business_users?year=${year}&month=${month}&day=${day}&date_filter_type=${type}&user_type=1&pagination=${false}`, header)

        dispatch({ type: "business_users_all_data", business_users_all_data: res.data });
    }
}

export const show_user_by_token = (token) => {
    return async (dispatch) => {
        const res = await axios.post(`${VODOGLOBAL_ACOUNTING_API}/api/user?with_business=true`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            if (response.data && response.data.status) {
                sessionStorage.setItem("secure_key", JSON.stringify(response.data.data.secure_key));
            } else if (response.data && response.data.status == false) {
                sessionStorage.removeItem("token")
                sessionStorage.removeItem("secure_key")
            }
            return response
        }).catch(error => error.response);
        dispatch({ type: "login", login: res.data });
    }
}

export const customer_reservations_total = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`https://www.klar.sa/api/customer_reservations_total?secure_business_key=${secure_business}&limit=${data && data.limit}&from_date=${data && data.from_date}&to_date=${data && data.to_date}&pagination=true&page=${data && data.page}&data_type=${data && data.data_type}&date_filter_type=${data && data.date_filter_type}&day=${data && data.day}&month=${data && data.month}&year=${data && data.year}`, {}, {
            headers: {
                'Accept-Language': data && data.lang,

            }
        })
        dispatch({ type: "customer_reservations_total", payload: res.data });
    }
}
export const products_list = (type, id, lang, page) => {
    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_API}/api/inventories/${id}/products?page=${page}&paginate=10&pagination=${type == "products_list" ? true : false}`, {
            headers: {
                'Accept-Language': lang,
                apikey: "5f28583f26a1a",
                apiconnection: "appmobile",
                'secure-business-key': secure_business


            }
        })
        dispatch({ type: type, payload: res.data });
    }
}
export const detailsProduct = (type, id, lang, page) => {
    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_API}/api/products/${id}/inventories-details?page=${page}&paginate=10&pagination=${type === "detailsProduct_withPAges"}`, {
            headers: {
                'Accept-Language': lang,
                apikey: "5f28583f26a1a",
                apiconnection: "appmobile",
                'secure-business-key': secure_business


            }
        })
        dispatch({ type: type, payload: res.data });
    }
}
export const customers_num_reservations = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`https://www.klar.sa/api/customers_num_reservations?secure_business_key=${secure_business}&&from_date=${data && data.from_date}&to_date=${data && data.to_date}&date_filter_type=${data && data.date_filter_type}&day=${data && data.day}&month=${data && data.month}&year=${data && data.year}&data_type=top`, {}, {
            headers: {

                'Accept-Language': data && data.lang,
            }
        })
        dispatch({ type: "customers_num_reservations", payload: res.data });
    }
}
export const specialists_services_totals = (data, list_type) => {

    return async (dispatch) => {
        const res = await axios.get(`https://www.klar.sa/api/specialists_services_totals?secure_business_key=${secure_business}&list_type=${list_type}&limit=${data && data.limit}&pagination=${data && data.pagination}&page=${data && data.page}&from_date=${data && data.from_date}&to_date=${data && data.to_date}&date_filter_type=${data && data.date_filter_type}&day=${data && data.day}&month=${data && data.month}&year=${data && data.year}&data_type=${data && data.data_type}&result_type=list `, {
            headers: {

                'Accept-Language': data && data.lang,

            }
        })

        dispatch({ type: "specialists_services_totals", payload: res.data });
    }
}
export const specialists_services_chart = (data, list_type) => {
    return async (dispatch) => {
        const res = await axios.get(`https://www.klar.sa/api/specialists_services_totals?secure_business_key=${secure_business}&list_type=${list_type}&limit=${data && data.limit}&pagination=${data && data.pagination}&page=${data && data.page}&from_date=${data && data.from_date}&to_date=${data && data.to_date}&date_filter_type=${data && data.date_filter_type}&day=${data && data.day}&month=${data && data.month}&year=${data && data.year}&result_type=chart&data_type=top  `, {
            headers: {

                'Accept-Language': data && data.lang,

            }
        })

        dispatch({ type: "specialists_services_chart", payload: res.data });
    }
}
export const login = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`${VODOGLOBAL_ACOUNTING_API}/api/login`, data, {
            headers: {
            }
        }).then((response) => {

            if (response.data && response.data.status) {

                sessionStorage.setItem("token", JSON.stringify(response.data.data.token));
                sessionStorage.setItem("secure_key", JSON.stringify(response.data.data.secure_key));
            }
            return response
        }).catch(error => error.response);

        dispatch({ type: "login", login: res.data });
    }
}

