import React from "react";
import "./style.css";
import { FormatDataHook } from "../../hooks/formatDataHook";
export default function Tabel_Customers_Suppliers({ data, tableRef, IdName }) {
  const [formatWithOutNumber] = FormatDataHook(false);

  return (
    <>
      <table
        id={IdName}
        ref={tableRef}
        className="table table-hover  table-striped    "
      >
        <thead className="dark_tabel_head">
          <tr className="text-center">
            <th>Id</th>
            <th> name</th>
            <th> company name</th>
            <th>phone </th>
            <th>Created Date</th>
            <th>address </th>
            <th> invices</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => (
              <tr className="pointer" key={item.id}>
                <td>{item.id}</td>
                <td>
                  {" "}
                  {item.first_name} {item.last_name}{" "}
                </td>
                <td>{item.company_name}</td>
                <td>{item.phone}</td>

                <td>{formatWithOutNumber(item.created_at)}</td>
                <td>{item.address1}</td>

                <td>
                  {" "}
                  <button
                    // onClick={() => go_to_details_delegate(item.id)}
                    className="btn btn-outline-danger w-100"
                  >
                    {item.invoices_count}
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
