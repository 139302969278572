import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FormatDataDayHook } from "../../hooks/formatDataHook";

export default function FilterDay({ year, month, setDay, Day }) {
  const [formatDay] = FormatDataDayHook();
  const currentDate = new Date();
  useEffect(() => {
    const currentDay = currentDate.getDate();
    if ((year, month)) {
      const defaultValue = new Date(`${year}/${month}/${currentDay + 1}`)
        .toISOString()
        .split("T")[0]; // yyyy-mm-dd
      setDay(defaultValue);
    }
  }, [year, month]);
  let maxDay = "";
  let minDay = "";
  if (year && month) {
    minDay = new Date(`${year}/${month}/02`).toISOString().split("T")[0]; // yyyy-mm-dd
    if (month != 12) {
      maxDay = new Date(`${year}/${Number(month) + 1}/01`)
        .toISOString()
        .split("T")[0]; // yyyy-mm-dd
    } else {
      maxDay = new Date(`${Number(year) + 1}/1/1 `).toISOString().split("T")[0]; // yyyy-mm-dd
    }
  }
  return (
    <>
      <div className="position-relative  ">
        <div className="position-relative parned_input  ">
          <label htmlFor="date_input" className="date_input_label">
            {Day && formatDay(`${Day}`)}
          </label>
          <input
            value={Day}
            min={minDay}
            max={maxDay}
            onChange={(e) => setDay(e.target.value)}
            id="date_input"
            type="date"
          />
        </div>
      </div>
    </>
  );
}
