import React from 'react'
import "./style.css"
export default function TableComp() {
  return (
   <>
   
   <table className="table table-hover  table-striped main_table ">
        <thead>
            <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Nameof Course</th>
                <th>Hours</th>
                <th>Cost</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>12/5/2022</td>
                <td>Extrnall</td>
                <td>Effective Presentation Skills</td>
                <td>38</td>
                <td>$1.453</td>
            </tr>
            <tr>
                <td>12/5/2022</td>
                <td>Extrnall</td>
                <td>Effective Presentation Skills</td>
                <td>38</td>
                <td>$1.453</td>
            </tr>
            <tr>
                <td>12/5/2022</td>
                <td>Extrnall</td>
                <td>Effective Presentation Skills</td>
                <td>38</td>
                <td>$1.453</td>
            </tr>
            <tr>
                <td>12/5/2022</td>
                <td>Extrnall</td>
                <td>Effective Presentation Skills</td>
                <td>38</td>
                <td>$1.453</td>
            </tr>
            <tr>
                <td>12/5/2022</td>
                <td>Extrnall</td>
                <td>Effective Presentation Skills</td>
                <td>38</td>
                <td>$1.453</td>
            </tr>
            <tr>
                <td>12/5/2022</td>
                <td>Extrnall</td>
                <td>Effective Presentation Skills</td>
                <td>38</td>
                <td>$1.453</td>
            </tr>
            <tr>
                <td>12/5/2022</td>
                <td>Extrnall</td>
                <td>Effective Presentation Skills</td>
                <td>38</td>
                <td>$1.453</td>
            </tr>
            <tr className='total_table'>
                <td colSpan={3}>Total</td>
      
                <td>38</td>
                <td>$1.453</td>
            </tr>
        </tbody>
   </table>
   </>
  )
}
