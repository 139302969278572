import { t } from "i18next";
import React from "react";

export default function HtabelInventories({ children, customClass }) {
  return (
    <>
      <thead className={customClass}>
        {children}
        <tr className="text-center">
          <th>{t("Id")}</th>
          <th> {t("name")}</th>
          <th> {t("Address")}</th>
          <th>{t("Created Date")}</th>
          <th>{t("Inventory Secretary")}</th>
          <th>{t("mobile")}</th>
          <th> </th>
        </tr>
      </thead>
    </>
  );
}
