import React, { Component } from "react";
import Chart from "react-apexcharts";

const ChartTwoLiner = () => {
  let arraydata = [1991, 1992, 1993, 1994, 1995, 1996];
  let arrayvalue = [30, 40, 45, 50, 49, 60];
  let arrayvalue2 = [10, 30, 25, 6, 30, 15];
  const filterDataChart = (data) => {};
  const data = {
    options: {
      stroke: {
        show: true,
        curve: "straight",
      },

      chart: {
        events: {
          click: function (event, chartContext, config) {
            filterDataChart(config.dataPointIndex);
          },
        },

        background: "none",
        id: "basic-bar",
      },
      xaxis: {
        categories: arraydata,
      },
      colors: ["#E83C33", "#E83C33"],
    },
    series: [
      {
        name: "series-1",
        data: arrayvalue,
      },
      {
        name: "series-1",
        data: arrayvalue2,
      },
    ],
  };

  return (
    <Chart
      options={data.options}
      series={data.series}
      type="line"
      height="400px"
      width="100%"
    />
  );
};
export default ChartTwoLiner;
