import React from "react";
import { useTranslation } from "react-i18next";
import { FormatDataYearHook } from "../../hooks/formatDataHook";
import FilterDay from "./FilterDay";
import LiInputRadio from "./LiInputRadio";
import "./style.css";
export default function FilterTime({
  setYear,
  setMonth,
  type,
  setType,
  month,
  year,
  setDay,
  Day,
}) {
  const { t, i18n } = useTranslation();
  const [formatYear] = FormatDataYearHook();
  const years = [];
  const currentYear = new Date().getFullYear();

  for (let year = 2019; year <= currentYear; year++) {
    years.push(year);
  }

  return (
    <>
      <div className="form_filter ">
        <div className="row  mb-2 m-auto">
          <div className="col-12  mt-2  col-sm-4">
            <form
              className="shadow d-flex align-items-center justify-content-center h-100 p-1"
              onChange={(e) => setYear(e.target.value)}
            >
              <ul className="donate-now text-center ">
                {years &&
                  years.map((oneYears, index) => (
                    <LiInputRadio
                      key={index}
                      activ={year}
                      value={`${oneYears}`}
                      nameID={"testa"}
                      name={"year"}
                      labelName={formatYear(`${oneYears}`)}
                    />
                  ))}
              </ul>
            </form>
          </div>
          <div className="col-12 col-md-4 col-sm-8  mt-2   text-center position-relative">
            <form
              className="shadow  d-flex align-items-center justify-content-center  h-100 p-1"
              onChange={(e) => setMonth(e.target.value)}
            >
              <ul className="donate-now  ">
                <LiInputRadio
                  activ={month}
                  value={"1"}
                  nameID={"Jan"}
                  name={"year"}
                  labelName={"Jan"}
                />
                <LiInputRadio
                  activ={month}
                  value={"2"}
                  nameID={"Feb"}
                  name={"year"}
                  labelName={"Feb"}
                />
                <LiInputRadio
                  activ={month}
                  value={"3"}
                  nameID={"Mar"}
                  name={"year"}
                  labelName={"Mar"}
                />
                <LiInputRadio
                  activ={month}
                  value={"4"}
                  nameID={"Apr"}
                  name={"year"}
                  labelName={"Apr"}
                />
                <LiInputRadio
                  activ={month}
                  value={"5"}
                  nameID={"May"}
                  name={"year"}
                  labelName={"May"}
                />
                <LiInputRadio
                  activ={month}
                  value={"6"}
                  nameID={"Jun"}
                  name={"year"}
                  labelName={"Jun"}
                />
                <LiInputRadio
                  activ={month}
                  value={"7"}
                  nameID={"Jul"}
                  name={"year"}
                  labelName={"Jul"}
                />
                <LiInputRadio
                  activ={month}
                  value={"8"}
                  nameID={"Aug"}
                  name={"year"}
                  labelName={"Aug"}
                />
                <LiInputRadio
                  activ={month}
                  value={"9"}
                  nameID={"Sep"}
                  name={"year"}
                  labelName={"Sep"}
                />
                <LiInputRadio
                  activ={month}
                  value={"10"}
                  nameID={"Oct"}
                  name={"year"}
                  labelName={"Oct"}
                />
                <LiInputRadio
                  activ={month}
                  value={"11"}
                  nameID={"Nov"}
                  name={"year"}
                  labelName={"Nov"}
                />
                <LiInputRadio
                  activ={month}
                  value={"12"}
                  nameID={"Dec"}
                  name={"year"}
                  labelName={"Dec"}
                />
              </ul>
            </form>
          </div>
          <div className="col-5 col-md-2 shadow mt-2   d-flex align-items-center justify-content-center         text-center   d-flex  flex-column    align-items-center justify-content-center ">
            <FilterDay year={year} month={month} setDay={setDay} Day={Day} />
          </div>
          <div className="col-7   col-md-2 position-relative mt-2 ">
            <div className=" shadow  d-flex align-items-center justify-content-center  h-100 d-flex  flex-column  p-2  align-items-center     justify-content-center  ">
              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="form-select my-2 mt-3 m-sm-0"
                aria-label="Default select example"
              >
                <option value={"year"}>{t("year")}</option>
                <option value={"month"}>{t("month")}</option>
                <option value={"daily"}>{t("daily")}</option>
                <option value={"all"}>{t("all")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
