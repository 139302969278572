import React from "react";
import Chart from "react-apexcharts";

const ChartGradient = () => {
  let arraydata = [1991, 1992, 1993, 1994, 1995, 1996];
  let arrayvalue = [30, 40, 45, 50, 20, 10];

  const data = {
    options: {
      tooltip: {
        enabled: false,
        enabledOnSeries: undefined,
        shared: false,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: true,
          format: "dd MMM",
          formatter: undefined,
        },
        y: {
          formatter: undefined,
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        z: {
          formatter: undefined,
          title: "Size: ",
        },
        marker: {
          show: true,
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },

      chart: {
        background: "none",
        id: "basic-bar",
        fill: {
          type: "gradient",
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: arraydata,
      },
      colors: ["#E2E2E2", "#E2E2E2"],
      yaxis: {
        show: false,
      },
    },
    series: [
      {
        name: "series-1",
        data: arrayvalue,
      },
    ],
  };

  return (
    <Chart
      options={data.options}
      series={data.series}
      type="area"
      height="150px"
      width="100%"
    />
  );
};
export default ChartGradient;
