import React from "react";
import { useTranslation } from "react-i18next";
import { FormatNumberHook } from "../../hooks/FormatNumber";
import ButtonFullScreen from "../buttonFullScreen/ButtonFullScreen";
import ChartBarCounting from "../charts/ChartBarCounting";
import "./style.css";
const ChartBarOneWithFullScreenCopm = ({
  full_screen,
  NameSection,
  created_date,
  arrayvalue,
  TotalCalc,
  setfull_screen,
  title,
  labelAsid,
  labelBottom,
}) => {
  const { t, i18n } = useTranslation();
  const [langNumber] = FormatNumberHook();
  let lang = i18n.language;
  return (
    <div
      className={`shadow m-1  ${NameSection == full_screen && "full_screen"} `}
    >
      <ButtonFullScreen
        NameType={NameSection}
        full_screen={full_screen}
        setfull_screen={setfull_screen}
      />
      <h4 className="text-center"> {t(title)} </h4>
      <ChartBarCounting
        title={title}
        valueHight={NameSection == full_screen ? "95%" : "400px"}
        created_date={created_date}
        arrayvalue={arrayvalue}
        labelAsid={labelAsid}
      />
      {labelBottom && (
        <div className="text-center labelName_bottom">{t(labelBottom)}</div>
      )}
      <div className="  shadow d-flex justify-content-center  align-items-center  div_number_chart">
        {" "}
        {TotalCalc && ` ${langNumber(TotalCalc)}  ${t("K")} `}{" "}
      </div>
    </div>
  );
};
export default ChartBarOneWithFullScreenCopm;
