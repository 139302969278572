import React from "react";
import Chart from "react-apexcharts";

export default function ChartBarStacked({ fullWidth, valueHight }) {
  let FullWidth = fullWidth ? fullWidth : null;
  const filterDataChart = (data) => {};
  const data = {
    options: {
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      grid: {
        show: false,
      },
      stroke: {
        show: false,
      },

      chart: {
        stacked: true,

        stackType: FullWidth,
        background: "none",
        id: "basic-bar",
        events: {
          click: function (event, chartContext, config) {
            filterDataChart(config.dataPointIndex);
          },
        },
      },
      xaxis: {
        categories: ["categories 1", "categories 2", " categories 3"],
      },

      yaxis: {
        show: true,
      },
    },

    series: [
      {
        name: "Tank Picture",
        data: [12, 17, 11],
      },
      {
        name: "Bucket Slope",
        data: [9, 7, 5],
      },
      {
        name: "Reborn Kid",
        data: [25, 12, 19],
      },
    ],
  };

  return (
    <>
      <Chart
        options={data.options}
        series={data.series}
        type="bar"
        height={valueHight ? valueHight : 200}
        width="100%"
      />
    </>
  );
}
