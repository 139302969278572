import React from "react";
import { Navigate } from "react-router";

const PrivateRouteKlar = ({ children }) => {
  const secure_business = JSON.parse(sessionStorage.getItem("secure_key"));

  return secure_business === "574526399db2b55b5a7.88351406" ? (
    children
  ) : (
    <Navigate to={"/"} />
  );
};

export default PrivateRouteKlar;
