import React, { useState, useEffect } from "react";
import "./style.css";
import { t } from "i18next";
const SearchAndSelect = ({
  searchResults,
  searchTerm,
  setSearchTerm,
  change_product,
  placeholder,
}) => {
  return (
    <div className="container mt-4">
      <div>
        <div>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control"
            placeholder={t(placeholder)}
          />
          <ul className="list-group mt-3 custom_result">
            {searchResults &&
              searchResults.map((item) => (
                <li
                  key={item.id}
                  onClick={() => {
                    change_product(item);
                  }}
                  className={`list-group-item  `}
                >
                  {item.name}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SearchAndSelect;
