import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import React, { useState, useEffect } from "react";
const Mapping = (props) => {
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [mapCenter, setMapCenter] = useState({ lat: "", lng: "" });
    const options2 = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, console.error, options2);
    }, []);
    const success = (pos) => {
        const crd = pos.coords;
        setMapCenter({ lat: crd.latitude, lng: crd.longitude });
    };


    const onMapClicked = (props) => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false);
        }
    };
    const { google, MarketPlace, hendling_action_marker } = props;
    const style = {
        width: "98%",
        height: "300px",



    };
    return (
        <>
            <div className="    position-relative">
                {mapCenter.lat && <Map
                    google={google}
                    zoom={5}
                    style={style}
                    initialCenter={{ lat: mapCenter.lat, lng: mapCenter.lng }}
                    onClick={onMapClicked}
                // onReady={fetchPlaces}
                >
                    {
                        MarketPlace && MarketPlace.map((item, index) => (
                            <Marker
                                onClick={() => hendling_action_marker && hendling_action_marker(item.id)}
                                key={index}
                                title={item.name}
                                id={index}
                                draggable={false}
                                position={{ lat: item.lat, lng: item.lng }}
                            // icon={{
                            //     url: "data:image/svg+xml;charset=UTF-8,%3Csvg%20viewBox%3D%220%200%2050%2050%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2225%22%20cy%3D%2225%22%20r%3D%2215%22%20fill%3D%22red%22%20opacity%3D%220.5%22%20%2F%3E%3C%2Fsvg%3E",
                            //     anchor: new google.maps.Point(16, 16),
                            //     scaledSize: new google.maps.Size(32, 32),
                            // }}
                            />
                        ))
                    }


                </Map>}
            </div>

        </>
    );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyBRR-QRcDqReT8KiMnYUhKc4lQz_ZLx70s",
})(Mapping);