import React from "react";
import { FormatDataHook } from "../../../hooks/formatDataHook";
import { useNavigate } from "react-router";
import { FiExternalLink } from "react-icons/fi";
import "../style.css";
import DefouldImg from "../../../hooks/DefouldImg";
export default function TbodyProduct({ data, orders }) {
  const navgite = useNavigate();
  const [imgDefuild] = DefouldImg();
  return (
    <>
      <tbody>
        {data &&
          data.map((item) => (
            <tr className="pointer text-center" key={item.id}>
              <td>{item.id}</td>
              <td>
                <img
                  src={item.image}
                  width={48}
                  height={48}
                  onError={imgDefuild}
                  alt=""
                  className="m-auto"
                />{" "}
              </td>
              <td>{item.name}</td>
              <td>{item.desc}</td>

              <td>{item.stock}</td>
              <td>
                <button
                  className="btn Activ_btn_action"
                  onClick={() => navgite(`/DetailsProduct/${item.id}`)}
                >
                  <FiExternalLink size={22} />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </>
  );
}
