import React from 'react'
import ChartBar from '../../component/charts/ChartBar'
 import HeaderPages from '../../component/HeaderPages/HeaderPages'
import TableComp from '../../component/tableComp/TableComp'
 import "./style.css"
export default function EmployeeProfile() {
  return (
    <>
    <section className='container-xxl EmployeeProfile'>
     <HeaderPages  title={"Employee Profile"}  >
       </HeaderPages>
        <div className='main_back_page px-2 py-1'>
        <div className="row">
            <div className="col-12 col-sm-6 col-md-4 ">
                <div className='shadow  back_main my-2'>
                    <p className='text-center mb-1 back_Personal_P'>Personal Information - Active</p>
                    <ul className=' list-group  p-2'>
                        <li className='row ' >
                         <div className="col-6  fw-bold ">
                         Employee NAme
                         </div>
                         <div className="col-6 action_li">
                         Employee NAme
                         </div>
                       </li>
                       <li className='row ' >
                         <div className="col-6 fw-bold ">
                         Employee NAme
                         </div>
                         <div className="col-6 action_li">
                         Employee NAme
                         </div>
                       </li>
                       <li className='row ' >
                         <div className="col-6 fw-bold ">
                         Employee NAme
                         </div>
                         <div className="col-6 action_li">
                         Employee NAme
                         </div>
                       </li>
                    </ul>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4  ">
                <div className='shadow  back_main my-2'>
                    <p className='text-center mb-1 back_Personal_P'>Personal Information - Active</p>
                    <ul className=' list-group  p-2'>
                        <li className='row ' >
                         <div className="col-6  fw-bold ">
                         Employee NAme
                         </div>
                         <div className="col-6 action_li">
                         Employee NAme
                         </div>
                       </li>
                       <li className='row ' >
                         <div className="col-6 fw-bold ">
                         Employee NAme
                         </div>
                         <div className="col-6 action_li">
                         Employee NAme
                         </div>
                       </li>
                       <li className='row ' >
                         <div className="col-6 fw-bold ">
                         Employee NAme
                         </div>
                         <div className="col-6 action_li">
                         Employee NAme
                         </div>
                       </li>
                    </ul>
                </div>
            </div>
            <div className="col-12  col-md-4 ">
                <div className='shadow  back_main my-2'>
                    <p className='text-center mb-1 back_Personal_P'>Personal Information - Active</p>
                    <ul className=' list-group  p-2'>
                        <li className='row ' >
                         <div className="col-6  fw-bold ">
                         Employee NAme
                         </div>
                         <div className="col-6 action_li">
                         Employee NAme
                         </div>
                       </li>
                       <li className='row ' >
                         <div className="col-6 fw-bold ">
                         Employee NAme
                         </div>
                         <div className="col-6 action_li">
                         Employee NAme
                         </div>
                       </li>
                       <li className='row ' >
                         <div className="col-6 fw-bold ">
                         Employee NAme
                         </div>
                         <div className="col-6 action_li">
                         Employee NAme
                         </div>
                       </li>
                    </ul>
                </div>
            </div>
            <div className="col-12 col-md-5  text-center">
             <div className='shadow back_main my-2 '>
                <p>Last Performance Rating</p>
                <h4 className='py-3'>Too early to evaluate</h4>
             </div>
            </div>
            <div className="col-12 col-md-7">
                <div className='row'>
                    <div className="col-12 col-sm-6  text-center">
                    <div className='shadow back_main my-2 '>
                        <p>Leave Taken</p>
                        <h4 > 24</h4>
                        <span>days</span>
                         </div>
                        </div>
                         <div className="col-16 col-sm-6  text-center">
                       <div className='shadow back_main my-2 '>
                        <p>Annual Leave balance</p>
                        <h4 > 124.4K</h4>
                        <span>days</span>
                    </div>
                    </div>
                </div>
            </div>
            
            <div className="col-12 col-md-5">
            <div className='shadow my-3 back_main overflow-auto div_tabel'>
                <TableComp/>
           </div>
           </div>
           <div className='col-12 col-md-7'>
             <div className='shadow my-3 back_main '>
                <p className='m-0 text-center'>Leave Days by Date</p>
              <ChartBar valueHight={300}/>
             </div>
           </div>
        </div>
        </div>
    </section>
    </>
  )
}
