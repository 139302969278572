import React from "react";
import { useTranslation } from "react-i18next";
import { FormatDataHook } from "../../hooks/formatDataHook";
import { FormatNumberHook } from "../../hooks/FormatNumber";
import "./style.css";
export default function TabelCompIvoice({ data, tableRef, IdName }) {
  const { t, i18n } = useTranslation();
  let lang = i18n.language;
  const [formatData] = FormatDataHook(true);
  const [formatWithOutNumber] = FormatDataHook(false);
  const [langNumber] = FormatNumberHook();
  return (
    <>
      <table
        id={IdName}
        ref={tableRef}
        className="table table-hover  table-striped    "
      >
        <thead className="dark_tabel_head">
          <tr>
            <th>{t("Id")}</th>
            <th>{t("Tax Total")}</th>
            <th>{t("Sub Total")}</th>
            <th>{t("Total")}</th>
            <th>{t("Type")}</th>
            <th>{t("Created Date")}</th>
            <th>{t("migration datetime")} </th>
            <th>{t("Created at")}</th>
            <th>{t("Updated at")}</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td> {langNumber(item.tax_total)}</td>
                  <td>{langNumber(item.sub_total)}</td>
                  <td> {langNumber(item.final_total)}</td>
                  <td>{t(item.type)}</td>
                  <td>{formatWithOutNumber(item.created_date)} </td>
                  <td>{formatData(item.migration_datetime)}</td>
                  <td>{formatData(item.created_at)}</td>
                  <td>{formatData(item.updated_at)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}
