import React from "react";
import "./style.css";
import ChartBarCountingMax from "../../component/charts/ChartBarCountingMax";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InvocesTotal, InvocesTotalChart } from "../../redux/action/action";
import { useState } from "react";
import Paginated from "../../component/paginate/paginate";
import TabelCompIvoice from "../../component/TabelComp/TabelCompIvoice";
import ButtonFullScreen from "../../component/buttonFullScreen/ButtonFullScreen";
import ChartBarOneWithFullScreenCopm from "../../component/ChartBarOneWithFullScreenCopm/ChartBarOneWithFullScreenCopm";
import BtnPdfExcelComp from "../../component/btnPdfExcelComp/BtnPdfExcelComp";
import { FormatNumberHook } from "../../hooks/FormatNumber";
import { FormatDataDayHook, FormatDataHook } from "../../hooks/formatDataHook";
import HeaderPages from "../../component/HeaderPages/HeaderPages";
import { FilterTimeHook } from "../../hooks/FilterTimeHook";
import SelectInput from "../../component/SelectInput/SelectInput";
import i18next, { t } from "i18next";
import FromToInputDate from "../../component/FromToInputDate/FromToInputDate";
import Top_Less_Limit from "../../component/Top_Less_Limit/Top_Less_Limit";
import SpinnerLoding from "../../component/SpinnerLoding/SpinnerLoding";
export default function Invoices() {
  const [pageNumber, setNumberpage] = useState(1);
  const [year, setYear, month, setMonth, Day, setDay, type, setType] =
    FilterTimeHook();
  const [typeInvoce, setTypeInvoce] = useState("sales_invoices");
  const [full_screen, setfull_screen] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [filter_Top_Less_user, setfilter_Top_Less_user] = useState("all");
  const [Limit_Top_Less_user, setLimit_Top_Less_user] = useState(10);
  const despatch = useDispatch();
  const [formatWithOutNumber] = FormatDataHook(false);
  const [langeNumber] = FormatNumberHook();
  const [formatDay] = FormatDataDayHook();
  let lang = i18next.language;

  const day = formatDay(Day);

  let dataFilter = {
    page: pageNumber,
    year: year,
    month: month,
    day: day,
    date_filter_type: type,
    to_date: toDate,
    from_date: fromDate,
    data_type: filter_Top_Less_user,
    limit: Limit_Top_Less_user,
    result_type: "list",
    pagination: true,
    lang: lang,
  };
  useEffect(() => {
    despatch(InvocesTotal(dataFilter, typeInvoce));
  }, [
    year,
    month,
    type,
    Day,
    pageNumber,
    filter_Top_Less_user,
    Limit_Top_Less_user,
    toDate,
    fromDate,
    lang,
    typeInvoce,
  ]);
  useEffect(() => {
    despatch(InvocesTotalChart(dataFilter, typeInvoce));
  }, [year, month, type, Day, toDate, fromDate, lang, typeInvoce]);
  const data = useSelector((state) => state.InvocesTotal);
  const dataChart = useSelector((state) => state.InvocesTotalChart);

  const tableRef = useRef(null);
  let final_total = [];
  let created_date = [];
  let sub_total = [];
  let tax_total = [];
  if (dataChart && dataChart.data) {
    dataChart.data.journals.forEach((item) => {
      final_total.push(item.final_total.toFixed(2));
      created_date.push(formatWithOutNumber(item.created_date));
      sub_total.push(item.sub_total.toFixed(2));
      tax_total.push(item.tax_total.toFixed(2));
    });
  }
  const handlePageClick = (e) => {
    setNumberpage(e.selected + 1);
  };
  const HendelSelectInvoce = (e) => {
    setTypeInvoce(e);
  };
  let SubTotal_1 = 0;
  let FinalTotal_1 = 0;
  let TaxTotal_1 = 0;
  if (dataChart && dataChart.data) {
    SubTotal_1 = langeNumber(dataChart.data.sub_total / 1000);
    FinalTotal_1 = langeNumber(dataChart.data.final_total / 1000);
    TaxTotal_1 = langeNumber(dataChart.data.tax_total / 1000);
  }
  let nameFile = "";
  if (dataChart && dataChart.data && dataChart.data.journals.length > 1) {
    nameFile = `${dataChart.data.journals[0].type} from ${
      dataChart.data.journals[0].created_date
    } to ${
      dataChart.data.journals[dataChart.data.journals.length - 1].created_date
    }  `;
  }

  const typeData = [
    { name: "sales_invoices", value: "sales_invoices", id: 1 },
    { name: "credit_notes", value: "credit_notes", id: 2 },
    { name: "purchase_invoices", value: "purchase_invoices", id: 3 },
    { name: "debit_notes", value: "debit_notes", id: 4 },
  ];

  return (
    <>
      <section className="container-xxl hr_page  my-2 py-2">
        <HeaderPages
          type={type}
          setType={setType}
          Day={Day}
          setDay={setDay}
          HendelSelect={HendelSelectInvoce}
          title={"Invoices"}
          setMonth={setMonth}
          setYear={setYear}
          year={year}
          typeData={typeData}
          month={month}
        >
          <div className="row">
            <div className="col-12 col-sm-6   ">
              <FromToInputDate
                fromDate={fromDate}
                setfromDate={setfromDate}
                toDate={toDate}
                settoDate={settoDate}
                custoClass={"d-flex flex-wrap"}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-2 mt-2">
              <SelectInput
                onHendelSelect={HendelSelectInvoce}
                data={typeData}
              />
            </div>
          </div>
        </HeaderPages>

        <div className="main_back_page">
          {dataChart && dataChart.status ? (
            <>
              {" "}
              <div className="row">
                <div className="col-12 col-md-4">
                  <ChartBarOneWithFullScreenCopm
                    title={"Total"}
                    full_screen={full_screen}
                    NameSection={"final_total"}
                    created_date={created_date}
                    arrayvalue={final_total}
                    TotalCalc={`${FinalTotal_1}`}
                    setfull_screen={setfull_screen}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <ChartBarOneWithFullScreenCopm
                    title={"Sub Total"}
                    full_screen={full_screen}
                    NameSection={"sub_total"}
                    created_date={created_date}
                    arrayvalue={sub_total}
                    TotalCalc={`${SubTotal_1}`}
                    setfull_screen={setfull_screen}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <ChartBarOneWithFullScreenCopm
                    title={"Tax Total"}
                    full_screen={full_screen}
                    NameSection={"tax_total"}
                    created_date={created_date}
                    arrayvalue={tax_total}
                    TotalCalc={`${TaxTotal_1}`}
                    setfull_screen={setfull_screen}
                  />
                </div>
              </div>
              <div
                className={`shadow my-2  ${
                  "All_data" === full_screen && "full_screen"
                } `}
              >
                <ButtonFullScreen
                  NameType="All_data"
                  full_screen={full_screen}
                  setfull_screen={setfull_screen}
                />
                <h4 className="text-center"> {t("All Data")} </h4>

                <ChartBarCountingMax
                  valueHight={"All_data" === full_screen && "100%"}
                  final_total={final_total}
                  created_date={created_date}
                  sub_total={sub_total}
                  tax_total={tax_total}
                />
              </div>
            </>
          ) : (
            <SpinnerLoding />
          )}
          {data && data.status ? (
            <div>
              <div className="d-flex justify-content-center  mt-5">
                <Top_Less_Limit
                  filter_Top_Less_user={filter_Top_Less_user}
                  setfilter_Top_Less_user={setfilter_Top_Less_user}
                  Limit_Top_Less_user={Limit_Top_Less_user}
                  setLimit_Top_Less_user={setLimit_Top_Less_user}
                  all={"All"}
                  less={"Less"}
                  top={"Top"}
                />
              </div>
              <div>
                <BtnPdfExcelComp
                  nameFile={nameFile}
                  namComp={"Invoices"}
                  dataChart={dataChart}
                >
                  {dataChart && dataChart.data && (
                    <TabelCompIvoice
                      data={dataChart.data.journals}
                      IdName={"table-to-xls"}
                      tableRef={tableRef}
                    />
                  )}
                </BtnPdfExcelComp>
                <div className="    overflow-auto Light_back">
                  {data && data.data && (
                    <TabelCompIvoice
                      data={data.data.data}
                      tableRef={tableRef}
                    />
                  )}
                </div>
              </div>
              <div className="d-felx d-flex  align-items-center  justify-content-center">
                {data && data.data && (
                  <Paginated
                    handlePageClick={handlePageClick}
                    pageCounts={data.data.last_page}
                  />
                )}
              </div>
            </div>
          ) : (
            <SpinnerLoding />
          )}
        </div>
      </section>
    </>
  );
}
