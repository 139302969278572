import { t } from "i18next";
import React from "react";

export default function FromToInputDate({
  fromDate,
  setfromDate,
  toDate,
  settoDate,
  custoClass,
}) {
  return (
    <>
      <div className={custoClass && custoClass}>
        <div className="   mt-2">
          <div className="row shadow    w-100 m-auto py-2 mx-1 align-items-center">
            <div className="col-auto">
              <label htmlFor="inputPassword6" className="col-form-label">
                {t("From")}
              </label>
            </div>
            <div className="col-auto">
              <input
                value={fromDate}
                onChange={(e) => setfromDate(e.target.value)}
                type="date"
                id="inputPassword6"
                className="form-control"
                aria-labelledby="passwordHelpInline"
              />
            </div>
          </div>
        </div>
        <div className="  mt-2">
          <div className="row   w-100 m-auto py-2 mx-1 shadow align-items-center">
            <div className="col-auto">
              <label htmlFor="inputPassword6" className="col-form-label">
                {t("To")}
              </label>
            </div>
            <div className="col-auto">
              <input
                value={toDate}
                onChange={(e) => settoDate(e.target.value)}
                type="date"
                id="inputPassword6"
                className="form-control"
                aria-labelledby="passwordHelpInline"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
