import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FiChevronsRight } from "react-icons/fi";
import { FiChevronsLeft } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import "./style.css"


function Paginated({ pageCounts, handlePageClick }) {
    const { t, i18n } = useTranslation();
    let lang = i18n.language
    return (
        <>
            <ReactPaginate
                nextLabel={lang == "en" ? <FiChevronsRight /> : <FiChevronsLeft />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCounts}
                previousLabel={lang == "en" ? <FiChevronsLeft /> : <FiChevronsRight />}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel={false}
                breakClassName="page-item-break"
                breakLinkClassName="page-link-break"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </>
    );
}
export default Paginated;