import React from "react";
import { FormatDataHook } from "../../../hooks/formatDataHook";

export default function Tbody({ data, setinvoices }) {
  const [formatWithOutNumber] = FormatDataHook(false);

  return (
    <>
      <tbody>
        {data &&
          data.map((item) => (
            <tr className="pointer" key={item.user_id}>
              <td className="centered-cell">{item.user_id}</td>
              <td className="centered-cell"> {item.name}</td>

              <td className="centered-cell">
                {item.invoices &&
                  item.invoices[0] &&
                  formatWithOutNumber(item.invoices[0].invoice_date)}{" "}
                {item.invoices.length > 1 &&
                  " : " +
                    formatWithOutNumber(
                      item.invoices[item.invoices.length - 1].invoice_date
                    )}
              </td>
              <td className="centered-cell">
                {" "}
                <button
                  onClick={() => setinvoices(item.invoices)}
                  className="btn btn-outline-danger w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#MoreDataModel"
                >
                  {item.final_total}
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </>
  );
}
