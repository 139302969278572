import React, { useState } from "react";
import ButtonFullScreen from "../../component/buttonFullScreen/ButtonFullScreen";
  import "./style.css";
  import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { account_totals } from "../../redux/action/action";
 import TabelRowHaveGroup from "../../component/TabelRowHaveGroup/TabelRowHaveGroup";
import ChartBarOneWithFullScreenCopm from "../../component/ChartBarOneWithFullScreenCopm/ChartBarOneWithFullScreenCopm";
import FormFilterChart from "../../component/formFilterChart/FormFilterChart";
 import HeaderPages from "../../component/HeaderPages/HeaderPages";
import { FilterTimeHook } from "../../hooks/FilterTimeHook";
import SelectInput from "../../component/SelectInput/SelectInput";
export default function BalanceSheet() {
  const [year,setYear,month,setMonth,Day,setDay,type,setType] = FilterTimeHook()

  const [full_screen, setfull_screen] = useState("");
  const date_chart = [1, 2, 3, 4, 5];
  const data_chart = [1, 2, 3, 4, 5];
 const desipsh = useDispatch()
 
useEffect(()=>{
  desipsh(account_totals())
},[desipsh])
 const data = useSelector(status => status.account_totals)
 
 const typeData =[]
 const HendelSelectInvoce =()=>{}
  return (
    <>
      <section className="container-xxl hr_page   mt-2">
     
       <HeaderPages type={type} setType={setType} Day={Day} setDay={setDay}   title={"Balance Sheet"} setMonth={setMonth} setYear={setYear} year={year}  month={month} >
       <div className="row">
          <div className="col-12 col-sm-6 col-md-3 col-lg-2 mt-2">
        <SelectInput onHendelSelect={HendelSelectInvoce} data={typeData}/>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-2 mt-2">
        <SelectInput onHendelSelect={HendelSelectInvoce} data={typeData}/>
          </div>
          
        </div>
       
       </HeaderPages>
        <div className="main_back_page">
          <div className="row  w-100  m-auto">
            <div className="col-12 col-md-7">
              <TabelRowHaveGroup  data={data} full_screen={full_screen} setfull_screen={setfull_screen}/>
            </div>
            <div className="col-12 col-md-5 ">
              <div
                className={`     m-1  ${
                  "Charts" === full_screen && "full_screen"
                } `}
              >
                <div className="mb-4"> 
                <ButtonFullScreen  full_screen={full_screen} setfull_screen={setfull_screen} NameType={"Charts"}/>
                </div>
              <FormFilterChart/>
          
                      <ChartBarOneWithFullScreenCopm  title={"Total"}  full_screen={full_screen} NameSection={"Charts"} created_date={date_chart} arrayvalue={data_chart}  setfull_screen={setfull_screen}/>

              </div>
             <TabelRowHaveGroup data={data} full_screen={full_screen} setfull_screen={setfull_screen}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
