import React from 'react'
import ChartArea from '../../component/charts/ChartArea'
import ChartBarStacked from '../../component/charts/ChartBarStacked'
import ChartBarStackedRevers from '../../component/charts/ChartBarStackedRevers'
import ChartDonut from '../../component/charts/ChartDonut'
 import HeaderPages from '../../component/HeaderPages/HeaderPages'
 
export default function Diversity() {
  return (
    <>
    <section className='container-xxl Diversity '>
    <HeaderPages  title={"Diversity"}  >
       </HeaderPages>
        <div className="main_back_page">
          <div className='row'>
            <div className="col-12 col-md-6 col-lg-5">
              <div className='shadow back_main my-2'>
                <p className='m-0 text-center '>% Female Leaders</p>
              <ChartArea valueHight={"300px"}/>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className='shadow back_main my-2'>
                <p className='m-0 text-center '>Headcount by Organisation Level and Generation </p>
              <ChartBarStacked valueHight={"300px"}  fullWidth="100%"/>
              </div>
            </div>
            <div className="col-12 col-lg-3  d-flex flex-column flex-md-row flex-lg-column justify-content-around">
                 <div className='shadow back_main my-2'>
                <p className='m-0 text-center '>  Hires by Generation </p>
              <ChartDonut valueHight={"150px"}/>
              </div>
               <div className='shadow back_main my-2'>
                <p className='m-0 text-center '> Headcount by Org Level and Gender </p>
                <ChartBarStacked valueHight={"150px"} fullWidth="100%"/>
              </div>
             
            </div>
            <div className="col-12 col-md-6">
              <div className='shadow back_main my-2'>
                <p className='m-0 text-center'>Gender Diversity by Business Unit</p>
                <ChartBarStackedRevers valueHight={300}/>
              </div>
            </div>
            <div className="col-12 col-md-6">
            <div className='shadow back_main my-2' >
                <p className='m-0 text-center '>headcount by Organisation Level and Age Banding</p>
              <ChartBarStacked valueHight={"300px"}  fullWidth="100%"/>
              </div>
            </div>
          </div>
        </div>


    </section>
    </>
  )
}
