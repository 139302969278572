import React from "react";
import Chart from "react-apexcharts";
import "./style.css";
import { t } from "i18next";
const ChartBarAside = ({ created_date, arrayvalue, valueHight, name }) => {
  const colors = arrayvalue.map((val) => (val < 0 ? "#E83C33" : "#00ADEF"));
  const data = {
    options: {
      chart: {
        type: "bar",
        height: 50,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: true,
          dataLabels: {
            position: "top", // top, center, bottom
          },
          barWidth: "90%",
          style: {
            fontSize: "15px",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetX: 30,

        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      colors: ["#E83C33", "#E83C33"],
      // colors: colors,

      xaxis: {
        categories: created_date,
      },
    },
    series: [
      {
        name: t(name),
        data: arrayvalue,
      },
    ],
  };

  return (
    <div
      style={{
        maxHeight: valueHight ? valueHight : "400px",
      }}
      className="overflow-auto ChartBarAside"
    >
      <Chart
        options={data.options}
        series={data.series}
        type="bar"
        width="100%"
        height={arrayvalue.length * 50}
      />
    </div>
  );
};

export default ChartBarAside;
