import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import SpinnerLoding from "../../component/SpinnerLoding/SpinnerLoding";
import BtnPdfExcelComp from "../../component/btnPdfExcelComp/BtnPdfExcelComp";
import Paginated from "../../component/paginate/paginate";
import i18next, { t } from "i18next";
import { useParams } from "react-router";
import { inventories, products_list } from "../../redux/action/action";
import TabeleProduct from "../../component/TabelComp/TabeleProduct";
import ChartBarAsideFullScreen from "../../component/ChartBarAsideFullScreen/ChartBarAsideFullScreen";
import SelectInput from "../../component/SelectInput/SelectInput";
export default function Inventories() {
  const [pageNumber, setNumberpage] = useState(1);
  const [changeInventories, setchangeInventories] = useState("");
  let lang = i18next.language;
  let param = useParams();
  const despatch = useDispatch();
  useEffect(() => {
    setchangeInventories(param.id);
  }, []);
  useEffect(() => {
    despatch(
      products_list("products_list", changeInventories, lang, pageNumber)
    );
  }, [lang, pageNumber, changeInventories]);
  useEffect(() => {
    despatch(inventories("allInventories", pageNumber));
  }, []);

  useEffect(() => {
    despatch(products_list("All_product_list", changeInventories, lang));
  }, [lang, changeInventories]);
  const [full_screen, setfull_screen] = useState("");

  const productsList = useSelector((state) => state.products_list);
  const AllProductList = useSelector((state) => state.All_product_list);
  const tableRef = useRef(null);
  const inventories_all_page = useSelector((state) => state.allInventories);

  const handlePageClick = (e) => {
    setNumberpage(e.selected + 1);
  };
  let nameFile = `${t("inventory")}_${
    productsList.status && productsList.data.inventory.name
  }`;
  let label_chart = [];
  let value_num = [];

  if (AllProductList && AllProductList.status) {
    let updatadata = AllProductList.data.products;
    updatadata.sort((a, b) => a.stock - b.stock).reverse();
    updatadata.forEach((item) => {
      label_chart.push(item.name);
      value_num.push(item.stock);
    });
  }
  return (
    <>
      <section className="container-xxl hr_page  my-2 py-2">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="">
            <h2 className="title_Page_border">
              {t("inventory")} :{" "}
              {productsList.status && productsList.data.inventory.name}{" "}
            </h2>
          </div>

          <div>
            {inventories_all_page.status && (
              <SelectInput
                value={changeInventories}
                onHendelSelect={setchangeInventories}
                data={inventories_all_page.data.inventories}
              />
            )}
          </div>
        </div>
        <div className="main_back_page">
          {AllProductList && AllProductList.status ? (
            <div className="  position-relative my-5">
              <ChartBarAsideFullScreen
                title={`Goods balances`}
                full_screen={full_screen}
                NameSection={"final_total"}
                created_date={label_chart}
                arrayvalue={value_num}
                setfull_screen={setfull_screen}
                name={"Reservations"}
              />
            </div>
          ) : (
            <SpinnerLoding />
          )}
          <div>
            {AllProductList && AllProductList.status && (
              <div>
                <BtnPdfExcelComp
                  nameFile={nameFile}
                  namComp={"ProductList"}
                  dataChart={AllProductList.data.products}
                  dataPrint={AllProductList.data.inventory}
                >
                  <TabeleProduct
                    data={AllProductList.data.products}
                    IdName={"table-to-xls"}
                    tableRef={tableRef}
                  />
                </BtnPdfExcelComp>
              </div>
            )}
            {productsList && productsList.status ? (
              <div className="    overflow-auto Light_back">
                <TabeleProduct
                  customClass="dark_tabel_head"
                  data={productsList.data.products.data}
                  tableRef={tableRef}
                />
              </div>
            ) : (
              <SpinnerLoding />
            )}
            <div className="d-felx d-flex  align-items-center  justify-content-center">
              {productsList && productsList.status && (
                <Paginated
                  handlePageClick={handlePageClick}
                  pageCounts={productsList.data.products?.last_page}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
