import React, { forwardRef } from "react";
import "./style.css";
import HeaderPDF from "./HeaderPDF";
import FooterPDF from "./FooterPDF";
import Tbody from "../TabelComp/TabelelReservations/Tbody";
import HeadTabel from "../TabelComp/TabelelReservations/HeadTabel";

const PdfTabelReservations = forwardRef((props, ref) => {
  return (
    <>
      <div ref={ref}>
        <table className="table  table-striped    ">
          <HeadTabel>
            <HeaderPDF />
          </HeadTabel>

          {props.data && <Tbody data={props.data} />}

          <tfoot>
            <FooterPDF />
          </tfoot>
        </table>
      </div>
    </>
  );
});

export default PdfTabelReservations;
