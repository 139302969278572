import React from "react";
import HTabeleProduct from "./HTabeleProduct";
import TbodyProduct from "./TbodyProduct";

export default function TabeleProduct({
  data,
  tableRef,
  IdName,
  customClass,
  setinvoices,
  orders,
}) {
  return (
    <>
      <table
        id={IdName}
        ref={tableRef}
        className="table table-hover  table-striped    "
      >
        <HTabeleProduct customClass={customClass} orders={orders} />
        <TbodyProduct orders={orders} data={data} setinvoices={setinvoices} />
      </table>
    </>
  );
}
