import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HrPage from './pages/hrPage/HrPage';
import DemogtaphicAnalysis from './pages/DemogtaphicAnalysis/DemogtaphicAnalysis';
import Diversity from './pages/Diversity/Diversity';
import EmployeeProfile from './pages/EmployeeProfile/EmployeeProfile';
import HistoricalTrends from './pages/HistoricalTrends/HistoricalTrends';
import Invoices from './pages/Invoices/Invoices';
import AssidBar from './layout/AssidBar/AssidBar';
import Navbar from './layout/navbar/Navbar';
import DelegateDetails from './pages/DelegateDetails/DelegateDetails';
import BalanceSheet from './pages/BalanceSheet/BalanceSheet';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import Login from './pages/login/Login';
import PrivateRoute from './layout/auth/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import { show_user_by_token } from './redux/action/action';
import NotFound from './pages/NotFound/NotFound';
import Customers from './pages/Customers/Customers';
import Suppliers from './pages/Suppliers/Suppliers';
import Reservations from './pages/Reservations/Reservations';
import ServicesKlar from './pages/ServicesKlar/ServicesKlar';
import Specialists from './pages/Specialists/Specialists';
import PrivateRouteKlar from './layout/auth/PrivateRouteKlar';
import InventoriesList from './pages/InventoriesList/InventoriesList';
import Inventories from './pages/Inventories/Inventories';
import DetailsProduct from './pages/DetailsProduct/DetailsProduct';
function App() {
  const { t, i18n } = useTranslation();
  const [styleLink, setStyleLink] = useState(null)
  const userData = useSelector(item => item.login)
  const token = JSON.parse(sessionStorage.getItem("token"));

  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(show_user_by_token(token));

    }
  }, []);


  const lang = i18n.language;
  useEffect(() => {
    if (lang === 'ar') {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = '/Ar.css'
      document.head.appendChild(link)
      setStyleLink(link)
    } else {
      if (styleLink) {
        document.head.removeChild(styleLink)
        setStyleLink(null)
      }
    }
  }, [lang])

  return (
    <>
      <BrowserRouter>

        {((userData && userData.status) || token) && <> <Navbar />     </>}

        <AssidBar />
        <Routes>
          <Route path="/" element={<PrivateRoute> <InventoriesList /></PrivateRoute>} />
          <Route path="/Login" element={<Login />} />
          <Route path="/HRAtGlance" element={<PrivateRoute> <HrPage /></PrivateRoute>} />
          <Route path="/DemogtaphicAnalysis" element={<PrivateRoute> <DemogtaphicAnalysis /></PrivateRoute>} />
          <Route path="/Diversity" element={<PrivateRoute> <Diversity /></PrivateRoute>} />
          <Route path="/EmployeeProfile" element={<PrivateRoute> <EmployeeProfile /></PrivateRoute>} />
          <Route path="/HistoricalTrends" element={<PrivateRoute> <HistoricalTrends /></PrivateRoute>} />
          <Route path="/Invoices" element={<PrivateRoute> <Invoices /></PrivateRoute>} />
          <Route path="/BalanceSheet" element={<PrivateRoute> <BalanceSheet /></PrivateRoute>} />
          <Route path="/Reservations" element={<PrivateRoute><PrivateRouteKlar>  <Reservations /></PrivateRouteKlar></PrivateRoute>} />
          <Route path="/Customers" element={<PrivateRoute> <Customers /></PrivateRoute>} />
          <Route path="/Suppliers" element={<PrivateRoute> <Suppliers /></PrivateRoute>} />
          <Route path="/Inventories" element={<PrivateRoute> <InventoriesList /></PrivateRoute>} />
          <Route path="/Services" element={<PrivateRoute><PrivateRouteKlar>  <ServicesKlar /></PrivateRouteKlar></PrivateRoute>} />
          <Route path="/Specialists" element={<PrivateRoute><PrivateRouteKlar> <Specialists /></PrivateRouteKlar></PrivateRoute>} />
          <Route path="*" element={<NotFound />} />
          <Route path="/DelegateDetails/:id" element={<PrivateRoute> <DelegateDetails /></PrivateRoute>} />
          <Route path="/Inventories/:id" element={<PrivateRoute> <Inventories /></PrivateRoute>} />
          <Route path="/DetailsProduct/:id" element={<PrivateRoute> <DetailsProduct /></PrivateRoute>} />
          <Route path="/DetailsProduct" element={<PrivateRoute> <DetailsProduct /></PrivateRoute>} />

        </Routes>

      </BrowserRouter>


    </>
  );
}

export default App;
