import React, { Component } from "react";
import Chart from "react-apexcharts";

const ChartBar = ({ valueHight }) => {
  let arraydata = [1991, 1992, 1993, 1994, 1995, 1996];
  let arrayvalue = [30, 40, 45, 50, 49, 60];
  const filterDataChart = (data) => {};
  const data = {
    options: {
      grid: {
        show: false,
      },
      stroke: {
        show: false,
      },

      chart: {
        events: {
          click: function (event, chartContext, config) {
            filterDataChart(config.dataPointIndex);
          },
        },

        background: "none",
        id: "basic-bar",
      },
      xaxis: {
        categories: arraydata,
      },
      colors: ["#E83C33", "#E83C33"],
      yaxis: {
        show: false,
      },
    },
    series: [
      {
        name: "series-1",
        data: arrayvalue,
      },
    ],
  };

  return (
    <Chart
      options={data.options}
      series={data.series}
      type="bar"
      height={valueHight ? valueHight : 200}
      width="100%"
    />
  );
};
export default ChartBar;
