import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
const ChartBarCounting = ({
  valueHight,
  created_date,
  arrayvalue,
  title,
  labelAsid,
}) => {
  const { t, i18n } = useTranslation();
  const filterDataChart = (data) => {};
  const data = {
    options: {
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -30,

        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      chart: {
        events: {
          click: function (event, chartContext, config) {
            filterDataChart(config.dataPointIndex);
          },
        },

        background: "none",
        id: "basic-bar",
      },

      xaxis: {
        categories: created_date,
      },
      yaxis: {
        title: {
          text: labelAsid && t(labelAsid),
          style: {
            fontSize: "16px", // Set the font size of the y-axis title
          },
        },
      },

      colors: ["#E83C33", "#E83C33"],
    },
    series: [
      {
        name: t(title),
        data: arrayvalue,
      },
    ],
  };

  return (
    <Chart
      options={data.options}
      series={data.series}
      type="bar"
      height={valueHight ? valueHight : 200}
      width="100%"
    />
  );
};
export default ChartBarCounting;
