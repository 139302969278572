 import React from "react";
 import "./style.css";
 import ChartBar from "../../component/charts/ChartBar";
 import ChaetDonut from "../../component/charts/ChartDonut";
 import { ImMan, ImWoman } from "react-icons/im";
 import ChartBarStacked from "../../component/charts/ChartBarStacked";
 import ChartBarWLiner from "../../component/charts/ChartBarWLiner";
 import ChartTwoLiner from "../../component/charts/ChartTwoLiner";
 import HeaderPages from "../../component/HeaderPages/HeaderPages";
export default function DemogtaphicAnalysis() {
  return (
    <>
      <section className="container-xxl DemogtaphicAnalysis  my-2 py-2">
          <HeaderPages  title={"Demogtaphic Analysis"}  >
          </HeaderPages>
        <div className="main_back_page p-2">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="row  text-center">
                <div className="col-4  ">
                  <div className="  back_main shadow">
                    <p>headcount</p>
                    <div className="pb-4">12.333</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="  back_main shadow">
                    <p>Hires</p>
                    <div className="pb-4">12.333</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="  back_main shadow">
                    <p>Terminations</p>
                    <div className="pb-4">12.333</div>
                  </div>
                </div>
              </div>
              <div className="back_main shadow text-center my-3">
                <p>headcount by fte category</p>
                <ChaetDonut />
              </div>
              <div className="my-2 text-center back_main shadow ">
                <p cl>Headcount by location</p>
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      width="100%"
                      height="300"
                      title="mapouter"
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      frameborder="0"
                       marginheight="0"
                      marginwidth="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 text-center">
              <div className="row back_main shadow w-100 m-auto ">
                <p>Demographic</p>
                <div className="col-3 ">
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <ImWoman size={45} />
                    <pan>
                      29% <br />
                      female{" "}
                    </pan>
                  </div>
                  <div className="d-flex     align-items-center justify-content-between my-2">
                    <ImMan size={45} />{" "}
                    <pan>
                      29% <br />
                      male{" "}
                    </pan>
                  </div>
                </div>
                <div className="col-3 befor_item_Border     position-relative">
                  <p>
                    Average <br />
                    Tenure
                  </p>
                </div>
                <div className="col-3 befor_item_Border     position-relative">
                  <p>
                    Average <br />
                    Age
                  </p>
                </div>
                <div className="col-3 befor_item_Border     position-relative">
                  <p>
                    Female % of <br />
                    High-level
                  </p>
                </div>
              </div>
              <div className="back_main shadow my-3">
                <p className="mb-0">
                  Employee distribution by Organisation Level and Status
                </p>
                <ChartBarStacked />
              </div>
              <div className="back_main shadow my-3">
                <p className="mb-0">
                  Employee distribution by salary range and gender
                </p>
                <ChartBarStacked fullWidth={"100%"} />
              </div>
            </div>
            <div className="col-12 col-lg-4 ">
              <div className="nav_section_chart back_main shadow">
                <ul className="nav nav-pills   " id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active     position-relative"
                      id="pills-Headcount-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Headcount"
                      type="button"
                      role="tab"
                      aria-controls="pills-Headcount"
                      aria-selected="true"
                    >
                      Headcount
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link position-relative"
                      id="pills-Hires-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Hires"
                      type="button"
                      role="tab"
                      aria-controls="pills-Hires"
                      aria-selected="false"
                    >
                      Hires
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link position-relative"
                      id="pills-Terminations-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Terminations"
                      type="button"
                      role="tab"
                      aria-controls="pills-Terminations"
                      aria-selected="false"
                    >
                      Terminations
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link position-relative"
                      id="pills-Attrition-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Attrition"
                      type="button"
                      role="tab"
                      aria-controls="pills-Attrition"
                      aria-selected="false"
                       
                    >
                      Attrition
                    </button>
                  </li>
                </ul>
               
                <div className="tab-content" id="pills-tabContent">
                <hr  className="mb-0 "/>

                  <div
                    className="tab-pane fade show active"
                    id="pills-Headcount"
                    role="tabpanel"
                    aria-labelledby="pills-Headcount-tab"
                    tabIndex="0"
                  >
                    <div>
 
                      <ChartBarWLiner />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-Hires"
                    role="tabpanel"
                    aria-labelledby="pills-Hires-tab"
                    tabIndex="0"
                  >
                    <ChartTwoLiner />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-Terminations"
                    role="tabpanel"
                    aria-labelledby="pills-Terminations-tab"
                    tabIndex="0"
                  >
                    <ChartTwoLiner />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-Attrition"
                    role="tabpanel"
                    aria-labelledby="pills-Attrition-tab"
                    tabIndex="0"
                  >
                    {" "}
                    <ChartTwoLiner />
                  </div>
                </div>
              </div>
              <div className="back_main shadow my-3">
                <p className="mb-0 text-center">headcount by tenure range</p>
                <ChartBar/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
