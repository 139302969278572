import React from "react";
import Customers_Suppliers_Comp from "../../component/Customers_Suppliers_Comp/Customers_Suppliers_Comp";

export default function Suppliers() {
  return (
    <>
      <Customers_Suppliers_Comp user_type={2} title={"Suppliers"} />
    </>
  );
}
