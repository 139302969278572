import React from "react";
import "./style.css";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  business_users_chart,
  customer_reservations_total,
  customers_num_reservations,
} from "../../redux/action/action";
import { useState } from "react";
import Paginated from "../../component/paginate/paginate";
import ChartBarOneWithFullScreenCopm from "../../component/ChartBarOneWithFullScreenCopm/ChartBarOneWithFullScreenCopm";
import BtnPdfExcelComp from "../../component/btnPdfExcelComp/BtnPdfExcelComp";
import { FormatDataDayHook, FormatDataHook } from "../../hooks/formatDataHook";
import HeaderPages from "../../component/HeaderPages/HeaderPages";
import { FilterTimeHook } from "../../hooks/FilterTimeHook";
import FromToInputDate from "../../component/FromToInputDate/FromToInputDate";
import SpinnerLoding from "../../component/SpinnerLoding/SpinnerLoding";
import ChartDountTotals from "../../component/chartDountTotals/chartDountTotals";
import Top_Less_Limit from "../../component/Top_Less_Limit/Top_Less_Limit";
import TabelelReservations from "../../component/TabelComp/TabelelReservations";
import MoreDataModel from "../../component/MoreDataModel/MoreDataModel";
import { t } from "i18next";
export default function Reservations() {
  const [pageNumber, setNumberpage] = useState(1);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [invoices, setinvoices] = useState([]);
  const [filter_Top_Less_user, setfilter_Top_Less_user] = useState("all");
  const [Limit_Top_Less_user, setLimit_Top_Less_user] = useState(10);
  const [year, setYear, month, setMonth, Day, setDay, type, setType] =
    FilterTimeHook();
  const [formatDay] = FormatDataDayHook();
  const [full_screen, setfull_screen] = useState("");
  const despatch = useDispatch();
  const day = formatDay(Day);
  // data by pages
  let data = {
    user_type: 1,
    page: pageNumber,
    year: year,
    month: month,
    day: day,
    date_filter_type: type,
    to_date: toDate,
    data_type: filter_Top_Less_user,
    from_date: fromDate,
    limit: Limit_Top_Less_user,
  };

  useEffect(() => {
    if (data) {
      despatch(customer_reservations_total(data));
    }
  }, [
    year,
    month,
    type,
    Day,
    pageNumber,
    filter_Top_Less_user,
    Limit_Top_Less_user,
    toDate,
    fromDate,
  ]);
  useEffect(() => {
    if (data && year && month && day && type) {
      despatch(customers_num_reservations(data));
    }
  }, [year, month, type, Day, filter_Top_Less_user, Limit_Top_Less_user]);
  const customerReservationsTotal = useSelector(
    (state) => state.customer_reservations_total
  );
  const businessUsersQty = useSelector(
    (state) => state.customers_num_reservations
  );
  const tableRef = useRef(null);
  let label_chart = [];
  let value_chart = [];
  if (businessUsersQty && businessUsersQty.status) {
    businessUsersQty.data.orders.forEach((item) => {
      label_chart.push(item.orders_num);
      value_chart.push(Number(item.client_num));
    });
  }
  const handlePageClick = (e) => {
    setNumberpage(e.selected + 1);
  };
  let nameFile = "";
  const [formatWithOutNumber] = FormatDataHook(false);
  const morddata = (invoices) => (
    <ul className="list-group p-2">
      {invoices.map((child, index) => (
        <li
          key={index}
          className="list-group-item d-flex justify-content-between align-items-center p-1"
        >
          {formatWithOutNumber(child.invoice_date)}
          <span>{child.total} </span>
        </li>
      ))}
    </ul>
  );
  return (
    <>
      <MoreDataModel>{morddata(invoices)}</MoreDataModel>
      <section className="container-xxl hr_page  my-2 py-2">
        <HeaderPages
          type={type}
          setType={setType}
          Day={Day}
          setDay={setDay}
          title={"Reservations"}
          setMonth={setMonth}
          setYear={setYear}
          year={year}
          month={month}
        >
          <div className="row">
            <div className="col-12  ">
              <FromToInputDate
                fromDate={fromDate}
                setfromDate={setfromDate}
                toDate={toDate}
                settoDate={settoDate}
                custoClass={"d-flex flex-wrap"}
              />
            </div>
          </div>
        </HeaderPages>
        <div className="main_back_page">
          <div
            className="list-group     d-flex flex-row align-items-center ustify-content-center"
            id="list-tab"
            role="tablist"
          >
            <button
              className="list-group-item   active"
              id="list-home-list"
              data-bs-toggle="list"
              href="#list-home"
              role="tab"
              aria-controls="list-home"
            >
              {t("By number")}
            </button>
            <button
              className="list-group-item "
              id="list-profile-list"
              data-bs-toggle="list"
              href="#list-profile"
              role="tab"
              aria-controls="list-profile"
            >
              {t("Percentage ratio")}
            </button>
          </div>

          {businessUsersQty && businessUsersQty.status ? (
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="list-home"
                role="tabpanel"
                aria-labelledby="list-home-list"
              >
                <div className="  position-relative">
                  <ChartBarOneWithFullScreenCopm
                    title={`Number of Reservations`}
                    full_screen={full_screen}
                    NameSection={"final_total"}
                    created_date={label_chart}
                    arrayvalue={value_chart}
                    labelAsid="Number of clients"
                    setfull_screen={setfull_screen}
                    labelBottom={`Number of Reservations`}
                  />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="list-profile"
                role="tabpanel"
                aria-labelledby="list-profile-list"
              >
                <div className="  position-relative ">
                  <ChartDountTotals
                    title={"Number of Reservations"}
                    arraylabel={label_chart}
                    arrayvalue={value_chart}
                    valueHight={400}
                    setfull_screen={setfull_screen}
                    full_screen={full_screen}
                    NameSection={"Reservations_Dount"}
                  />
                </div>
              </div>
            </div>
          ) : (
            <SpinnerLoding />
          )}
          <h3 className="text-center mt-5 mb-0">
            {t("Customers vs. Reservations")}
          </h3>
          <div>
            {customerReservationsTotal && customerReservationsTotal.status ? (
              <div>
                <BtnPdfExcelComp
                  nameFile={nameFile}
                  namComp={"Reservations"}
                  dataChart={customerReservationsTotal.data}
                >
                  {customerReservationsTotal &&
                    customerReservationsTotal.status && (
                      <TabelelReservations
                        data={customerReservationsTotal.data}
                        IdName={"table-to-xls"}
                        tableRef={tableRef}
                      />
                    )}
                </BtnPdfExcelComp>
                <div className="d-flex justify-content-center  ">
                  <Top_Less_Limit
                    filter_Top_Less_user={filter_Top_Less_user}
                    setfilter_Top_Less_user={setfilter_Top_Less_user}
                    Limit_Top_Less_user={Limit_Top_Less_user}
                    setLimit_Top_Less_user={setLimit_Top_Less_user}
                    all={"All"}
                    less={"Less"}
                    top={"Top"}
                  />
                </div>
                {/* tabel to view */}

                <div className="    overflow-auto Light_back">
                  {customerReservationsTotal &&
                    customerReservationsTotal.status && (
                      <TabelelReservations
                        customClass={"dark_tabel_head"}
                        data={customerReservationsTotal.data}
                        tableRef={tableRef}
                        setinvoices={setinvoices}
                      />
                    )}
                </div>
              </div>
            ) : (
              <SpinnerLoding />
            )}
            <div className="d-felx d-flex  align-items-center  justify-content-center">
              {customerReservationsTotal &&
                customerReservationsTotal.status && (
                  <Paginated
                    handlePageClick={handlePageClick}
                    pageCounts={customerReservationsTotal.pages_num}
                  />
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
