import React, { forwardRef } from "react";
import "./style.css";
import HeaderPDF from "./HeaderPDF";
import FooterPDF from "./FooterPDF";
import HtabelInventories from "../TabelComp/TabelelInventories/HtabelInventories";
import TbodyInventories from "../TabelComp/TabelelInventories/TbodyInventories";

const PdfTabelInventories = forwardRef((props, ref) => {
  return (
    <>
      <div ref={ref}>
        <table className="table  table-striped    ">
          <HtabelInventories orders={props.orders}>
            <HeaderPDF />
          </HtabelInventories>

          {props.data && (
            <TbodyInventories data={props.data} orders={props.orders} />
          )}

          <tfoot>
            <FooterPDF />
          </tfoot>
        </table>
      </div>
    </>
  );
});

export default PdfTabelInventories;
