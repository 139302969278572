import React from "react";
import "./style.css";
import ChartBarCountingMax from "../../component/charts/ChartBarCountingMax";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InvocesTotal, InvocesTotalChart } from "../../redux/action/action";
import { useState } from "react";
import Paginated from "../../component/paginate/paginate";
import ButtonFullScreen from "../../component/buttonFullScreen/ButtonFullScreen";
import TabelDelegateDetails from "../../component/TabelComp/TabelDelegateDetails";
import ChartBarOneWithFullScreenCopm from "../../component/ChartBarOneWithFullScreenCopm/ChartBarOneWithFullScreenCopm";
import BtnPdfExcelComp from "../../component/btnPdfExcelComp/BtnPdfExcelComp";
import HeaderPages from "../../component/HeaderPages/HeaderPages";
import SelectInput from "../../component/SelectInput/SelectInput";
import { FilterTimeHook } from "../../hooks/FilterTimeHook";

export default function DelegateDetails() {
  const [year, setYear, month, setMonth, Day, setDay, type, setType] =
    FilterTimeHook();
  const [full_screen, setfull_screen] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const despatch = useDispatch();
  useEffect(() => {
    despatch(InvocesTotal(month, year, type));
    despatch(InvocesTotalChart(month, year, type));
  }, [year, month, type, despatch]);
  const data = useSelector((state) => state.InvocesTotal);
  const dataChart = useSelector((state) => state.InvocesTotalChart);
  const tableRef = useRef(null);
  let final_total = [];
  let created_date = [];
  let sub_total = [];
  let tax_total = [];
  if (dataChart && dataChart.data) {
    dataChart.data.journals.forEach((item) => {
      final_total.push(item.final_total.toFixed(2));
      created_date.push(item.created_date);
      sub_total.push(item.sub_total.toFixed(2));
      tax_total.push(item.tax_total.toFixed(2));
    });
  }
  const handlePageClick = (e) => {
    despatch(InvocesTotal(month, year, type, e.selected + 1));
  };
  const HendelSelectInvoce = (e) => {
    setType(e);
  };
  let SubTotal_1 = 0;
  let FinalTotal_1 = 0;
  let TaxTotal_1 = 0;
  if (dataChart && dataChart.data) {
    SubTotal_1 = dataChart.data.sub_total / 1000;
    FinalTotal_1 = dataChart.data.final_total / 1000;
    TaxTotal_1 = dataChart.data.tax_total / 1000;
  }
  let nameFile = "";
  if (dataChart && dataChart.data && dataChart.data.journals.length > 1) {
    nameFile = `${dataChart.data.journals[0].type} from ${
      dataChart.data.journals[0].created_date
    } to ${
      dataChart.data.journals[dataChart.data.journals.length - 1].created_date
    }  `;
  }

  const typeData = [];
  return (
    <>
      <section className="container-xxl hr_page  my-2 py-2">
        <HeaderPages
          type={type}
          setType={setType}
          Day={Day}
          setDay={setDay}
          title={"Delegate Details"}
          setMonth={setMonth}
          setYear={setYear}
          year={year}
          month={month}
        >
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3 col-lg-2 mt-2">
              <SelectInput
                onHendelSelect={HendelSelectInvoce}
                data={typeData}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-2 mt-2">
              <SelectInput
                onHendelSelect={HendelSelectInvoce}
                data={typeData}
              />
            </div>
          </div>
        </HeaderPages>
        <div className="main_back_page">
          <div className="row">
            <div className="col-12 col-md-4">
              <ChartBarOneWithFullScreenCopm
                title={"Total"}
                full_screen={full_screen}
                NameSection={"final_total"}
                created_date={created_date}
                arrayvalue={final_total}
                TotalCalc={`${FinalTotal_1.toFixed(2)}`}
                setfull_screen={setfull_screen}
              />
            </div>
            <div className="col-12 col-md-4">
              <ChartBarOneWithFullScreenCopm
                title={" Sub Total"}
                full_screen={full_screen}
                NameSection={"sub_total"}
                created_date={created_date}
                arrayvalue={sub_total}
                TotalCalc={`${SubTotal_1.toFixed(2)}`}
                setfull_screen={setfull_screen}
              />
            </div>
            <div className="col-12 col-md-4">
              <ChartBarOneWithFullScreenCopm
                title={" Tax Total "}
                full_screen={full_screen}
                NameSection={"tax_total"}
                created_date={created_date}
                arrayvalue={tax_total}
                TotalCalc={`${TaxTotal_1.toFixed(2)}`}
                setfull_screen={setfull_screen}
              />
            </div>
          </div>
          <div
            className={`shadow my-2  ${
              "All_data" === full_screen && "full_screen"
            } `}
          >
            <ButtonFullScreen
              NameType="All_data"
              full_screen={full_screen}
              setfull_screen={setfull_screen}
            />
            <h4 className="text-center"> All Data </h4>

            <ChartBarCountingMax
              valueHight={"All_data" === full_screen && "100%"}
              final_total={final_total}
              created_date={created_date}
              sub_total={sub_total}
              tax_total={tax_total}
            />
          </div>
          <div>
            <div>
              <BtnPdfExcelComp
                nameFile={nameFile}
                namComp={"DelegateDetails"}
                dataChart={dataChart}
              >
                {dataChart && dataChart.data && (
                  <TabelDelegateDetails
                    data={dataChart.data.journals}
                    IdName={"table-to-xls"}
                    tableRef={tableRef}
                  />
                )}
              </BtnPdfExcelComp>
              <div className="    overflow-auto Light_back">
                {data && data.data && (
                  <TabelDelegateDetails
                    data={data.data.data}
                    tableRef={tableRef}
                  />
                )}
              </div>
            </div>
            <div className="d-felx d-flex  align-items-center  justify-content-center">
              {data && data.data && (
                <Paginated
                  handlePageClick={handlePageClick}
                  pageCounts={data.data.last_page}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
