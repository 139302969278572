import React from "react";
import "./style.css";
import { AiFillPushpin } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <section className="PageNotFound d-flex align-items-center  justify-content-center flex-column">
        <div className="position-relative">
          <span className="pin_icon_error">
            <AiFillPushpin />
          </span>

          <div className="shadow-lg p-5 masseg_erorr ">
            <div className="error-message "></div>
            <h1 className="fw-bold shadow p-2 rounded-3 ">Page Not Found</h1>
          </div>
        </div>
      </section>
      <div className="text-center">
        <Link to={"/"} className="btn red_btn">
          Home page
        </Link>
      </div>
    </>
  );
}
