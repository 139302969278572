import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function SelectLang() {
     const { t, i18n } = useTranslation();
 

   return (
    <>
         <select   onChange={(e) => i18n.changeLanguage(e.target.value)} className="form-select" aria-label="Default select example">
                 <option value="en" >En</option>
                  <option value="ar">Ar</option>
             </select>
    </>
  )
}
