import { t } from "i18next";
import React from "react";

export default function HeadTabel({ children, customClass, orders }) {
  return (
    <>
      <thead className={customClass}>
        {children}
        <tr className="text-center">
          <th>{t("Id")}</th>
          <th> {t("name")}</th>
          <th> {t("Reservations")}</th>
          {orders !== "services" && <th>{t("Invoices")}</th>}
        </tr>
      </thead>
    </>
  );
}
