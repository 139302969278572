import default_image from "../assset/image/default_image.512x512.webp";

export default function DefouldImg() {
  const replaceImage = (error) => {
    error.onerror = null; // prevents looping
    // check if the logo image exists
    const img = new Image();
    img.onerror = () => {
      // if the logo image is not found, use a placeholder image or an empty string
      error.target.src = ""; // or ''
    };
    img.src = default_image;

    // if the logo image is found, use it as the replacement image
    error.target.src = default_image;
  };

  return [replaceImage];
}
