import React from "react";
import ReactToPrint from "react-to-print";
import PdfTabelCompIvoice from "./PdfTabelCompIvoice";
import { GrDocumentPdf } from "react-icons/gr";
import PdfTabelDelegateDetails from "./PdfTabelDelegateDetails";
import PdfTabel_Customers_Suppliers from "./PdfTabel_Customers_Suppliers";
import PdfTabelReservations from "./PdfTabelReservations";
import PdfTabelSpecialistServices from "./PdfTabelSpecialistServices";
import PdfTabelInventories from "./PdfTabelInventories";
import PdfTabelProdect from "./PdfTabelProdect";
import PdfTabelDetailsProdect from "./PdfTabelDetailsProdect";
class PdfComponent extends React.Component {
  render() {
    const compentSwitsh = (key) => {
      switch (key) {
        case "Invoices":
          return (
            <PdfTabelCompIvoice
              data={this.props.dataChart}
              ref={(response) => (this.componentRef = response)}
            />
          );
        case "Delegates":
          return (
            <PdfTabel_Customers_Suppliers
              data={this.props.dataChart}
              ref={(response) => (this.componentRef = response)}
            />
          );
        case "DelegateDetails":
          return (
            <PdfTabelDelegateDetails
              data={this.props.dataChart}
              ref={(response) => (this.componentRef = response)}
            />
          );
        case "Reservations":
          return (
            <PdfTabelReservations
              data={this.props.dataChart}
              ref={(response) => (this.componentRef = response)}
            />
          );
        case "SpecialistServices":
          return (
            <PdfTabelSpecialistServices
              data={this.props.dataChart}
              orders={this.props.orders}
              ref={(response) => (this.componentRef = response)}
            />
          );
        case "Inventories":
          return (
            <PdfTabelInventories
              data={this.props.dataChart}
              orders={this.props.orders}
              ref={(response) => (this.componentRef = response)}
            />
          );
        case "ProductList":
          return (
            <PdfTabelProdect
              data={this.props.dataChart}
              dataPrint={this.props.dataPrint}
              orders={this.props.orders}
              ref={(response) => (this.componentRef = response)}
            />
          );
        case "ProductListDetails":
          return (
            <PdfTabelDetailsProdect
              data={this.props.dataChart}
              orders={this.props.orders}
              ref={(response) => (this.componentRef = response)}
            />
          );
        default:
          break;
      }
    };

    return (
      <div>
        <ReactToPrint
          content={() => this.componentRef}
          trigger={() => (
            <span className="pointer">
              {" "}
              <GrDocumentPdf size={28} />
            </span>
          )}
          documentTitle={this.props.nameFile}
        />

        <div className="d-none">
          {this.props.namComp && compentSwitsh(this.props.namComp)}
        </div>
      </div>
    );
  }
}

export default PdfComponent;
