import React from "react";
import "./style.css";
import { RotatingSquare } from "react-loader-spinner";

export default function SpinnerLoding() {
  return (
    <div className="spinner_container">
      <RotatingSquare
        color="#eb3d34"
        width="50%"
        height="100%"
        visible={true}
        ariaLabel="falling-lines-loading"
      />
    </div>
  );
}
