import React from 'react'
import { useTranslation } from 'react-i18next';
import ButtonFullScreen from '../buttonFullScreen/ButtonFullScreen'
import RowBalanceSheet from '../RowBalanceSheet/RowBalanceSheet'

export default function TabelRowHaveGroup({data,full_screen,setfull_screen}) {
  const { t, i18n } = useTranslation();

  return (
     <>
        <div className={` ${"tabel_data" ==full_screen && "full_screen_Tabel"} py-3`}>
                <div className={` ${"tabel_data" == full_screen ? "Max_h_tabel_full    ": "Max_h_tabel   "}      position-relative  overflow-auto shadow`}  >{/* overflow-auto*/}
                <div className="Full_screen">
              <ButtonFullScreen full_screen={full_screen} setfull_screen={setfull_screen} NameType={"tabel_data"}/>
              </div>
                 <div className="inser_tabel ">
                  <div className="row ">
                    <div className="col-4"></div>
                    <div className="col-2" ><div className="b_button_item">{t("Current")}</div> </div>
                    <div className="col-2"><div className="b_button_item">{t("Comparison")}</div></div>
                    <div className="col-2"><div className=" ">Var</div></div>
                    <div className="col-2"><div className=" ">Var %</div></div>
                  </div>
                   {
             data && data.data &&data.data.map((item ,index) =>(
               <div key={item.id} className="accordion-item hover_parant">
            <RowBalanceSheet   item={item}/>
              <div
                id={`Item${item.id}`}
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
             {item.account &&  item.account.map(account =>(
              <RowBalanceSheet key={account.id} item={account} classMargin={"item_5_m "} />
                 ))
                  }
                   {
                 item.subgroup && item.subgroup.map(subgroup =>(
                  <div key={subgroup.id} className="accordion-item">
                 <RowBalanceSheet item={subgroup} classMargin={"mx-2"} />
                   <div
                     id={`Item${subgroup.id}`}
                     className="accordion-collapse collapse"
                     aria-labelledby="flush-headingThree"
                     data-bs-parent="#accordionFlushExample"
                   >
                  {subgroup.account &&  subgroup.account.map(account =>(
                   <RowBalanceSheet key={account.id} item={account} classMargin={"item_5_m"} />
                  ))
                  }
                   </div>
                 </div>
                ))
             }
              </div>
            </div>
             ))
            }
              </div>
                </div>
            </div>
     </>
  )
}
