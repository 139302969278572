import React from "react";
import { useTranslation } from "react-i18next";

export const FormatDataHook = (Hours) => {
  const { t, i18n } = useTranslation();
  // let lang = i18n.language
  let lang = "en"

  var options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  };
  var option2 = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const formatData = (date) => {
    var date = new Date(date);

    return date.toLocaleString(lang == "en" ? 'en-US' : "ar-EG", Hours && Hours == true ? options : option2)
  }

  return [formatData]
}
export const FormatDataYearHook = () => {
  const { t, i18n } = useTranslation();
  // let lang = i18n.language
  let lang = "en"

  var options = {
    year: 'numeric',
  };
  const FormatDataYear = (date) => {
    var date = new Date(date);
    return date.toLocaleString(lang == "en" ? 'en-US' : "ar-EG", options)
  }
  return [FormatDataYear]
}

export const FormatDataMonthHook = () => {
  const { t, i18n } = useTranslation();
  // let lang = i18n.language
  let lang = "en"

  var options = {
    month: 'numeric',
  };
  const FormatDataYear = (date) => {
    var date = new Date(date);
    return date.toLocaleString(lang == "en" ? 'en-US' : "ar-EG", options)
  }
  return [FormatDataYear]
}
export const FormatDataDayHook = () => {
  const { t, i18n } = useTranslation();
  // let lang = i18n.language
  let lang = "en"

  var options = {
    day: 'numeric',
  };
  const FormatDataYear = (date) => {
    var date = new Date(date);
    return date.toLocaleString(lang == "en" ? 'en-US' : "ar-EG", options)
  }
  return [FormatDataYear]
}