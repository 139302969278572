import React from "react";
import log from "../../assset/image/VODOLOGO.png";
import { useSelector } from "react-redux";
import { t } from "i18next";

export default function HeaderPDF({ children }) {
  const userData = useSelector((item) => item.login);

  return (
    <>
      {userData && userData.status && (
        <tr>
          <td colSpan={9}>
            <div className=" d-flex justify-content-between py-2">
              <img
                width={50}
                height={50}
                src={userData?.data.business?.logo}
                alt=""
              />
              <div>
                <h4> {userData?.data.business?.name}</h4>
                <div className="text-center  ">
                  {userData?.data.business?.commercial_number}
                </div>
              </div>
            </div>

            {children}
          </td>
        </tr>
      )}
    </>
  );
}
