import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { inventories } from "../../redux/action/action";
import { useState } from "react";

import Mapping from "../../component/mapping/Mapping";
import SpinnerLoding from "../../component/SpinnerLoding/SpinnerLoding";
import BtnPdfExcelComp from "../../component/btnPdfExcelComp/BtnPdfExcelComp";
import Paginated from "../../component/paginate/paginate";
import TabelelInventories from "../../component/TabelComp/TabelelInventories";
import { t } from "i18next";
import ModalComp from "../../component/ModalComp/ModalComp";
import { useNavigate } from "react-router";
export default function InventoriesList() {
  const [pageNumber, setNumberpage] = useState(1);
  const [Inventorie, setInventorie] = useState("");
  const buttonModal = useRef();
  const navgite = useNavigate();
  const despatch = useDispatch();
  useEffect(() => {
    despatch(inventories("allInventories", pageNumber));
  }, []);
  useEffect(() => {
    despatch(inventories("inventories", pageNumber));
  }, [pageNumber]);
  const inventories_list = useSelector((state) => state.inventories);
  const inventories_all_page = useSelector((state) => state.allInventories);
  const tableRef = useRef(null);

  let MarketPlace = [];

  if (inventories_all_page && inventories_all_page.status) {
    inventories_all_page.data.inventories.forEach((item) => {
      item.latitude &&
        MarketPlace.push({
          lat: item.latitude,
          lng: item.longitude,
          name: item.name,
          id: item.id,
        });
    });
  }
  const handlePageClick = (e) => {
    setNumberpage(e.selected + 1);
  };
  let nameFile = t("Inventories");
  const hendling_action_marker = (id) => {
    let dataFilter = inventories_all_page?.data?.inventories.filter(
      (item) => item.id == id
    );
    setInventorie(...dataFilter);
    buttonModal.current.click();
  };
  return (
    <>
      <ModalComp buttonModal={buttonModal}>
        <div className="row">
          <h3 className="col-12 text-center py-1">
            {t("Inventory Secretary")} : {Inventorie.keeper?.name}
          </h3>
          <h3 className="col-12 text-center py-1">
            {t("mobile")} : {Inventorie.keeper?.mobile}
          </h3>

          <h3 className="col-12 text-center py-1">{Inventorie?.name}</h3>
          <div className="col-12 text-center">
            {t("address")} : {Inventorie?.address}
          </div>
        </div>
        <div className="text-center pt-3">
          <button
            className="  btn btn-outline-danger"
            onClick={() => {
              navgite(`/Inventories/${Inventorie?.id}`);
              buttonModal.current.click();
            }}
          >
            {t("Product in this stock")}
          </button>
        </div>
      </ModalComp>
      <section className="container-xxl hr_page  my-2 py-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="">
            <h2 className="title_Page_border">{t("Inventories")}</h2>
          </div>
        </div>
        <div className="main_back_page">
          <div>
            {inventories_all_page && inventories_all_page.status && (
              <div>
                <BtnPdfExcelComp
                  nameFile={nameFile}
                  namComp={"Inventories"}
                  dataChart={inventories_all_page.data.inventories}
                >
                  <TabelelInventories
                    data={inventories_all_page.data.inventories}
                    IdName={"table-to-xls"}
                    tableRef={tableRef}
                  />
                </BtnPdfExcelComp>
              </div>
            )}
            {inventories_list && inventories_list.status ? (
              <div className="    overflow-auto Light_back">
                <TabelelInventories
                  customClass="dark_tabel_head"
                  data={inventories_list.data.inventories.data}
                  tableRef={tableRef}
                />
              </div>
            ) : (
              <SpinnerLoding />
            )}
            <div className="d-felx d-flex  align-items-center  justify-content-center">
              {inventories_list && inventories_list.status && (
                <Paginated
                  handlePageClick={handlePageClick}
                  pageCounts={inventories_list.data?.inventories.last_page}
                />
              )}
            </div>
          </div>
          <div className=" map_div mt-3">
            <h2 className="text-center my-3"> {t("Inventories location")}</h2>
            {inventories_all_page && inventories_all_page.status ? (
              <Mapping
                hendling_action_marker={hendling_action_marker}
                MarketPlace={MarketPlace}
              />
            ) : (
              <SpinnerLoding />
            )}
          </div>
        </div>
      </section>
    </>
  );
}
