import { useEffect } from 'react'
import { useState } from 'react'
import { FormatDataMonthHook } from './formatDataHook'

export function FilterTimeHook() {
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")
  const [Day, setDay] = useState("")
  const [type, setType] = useState("year")
  const [formatMonth] = FormatDataMonthHook()
  useEffect(() => {
    let date = new Date()
    const NowMonth = formatMonth(date)
    const currentYear = new Date().getFullYear();
    const currentday = new Date().getDay();
    setMonth(NowMonth)
    setDay(currentday)
    setYear(currentYear)
  }, [])
  return [year, setYear, month, setMonth, Day, setDay, type, setType]
}
