import React from 'react'

export default function ProgresBarSheet ({Negative,Positive}) {


return(
    <div className="d-flex">
            <div className="  d-flex"    style={{width:  "45% " ,flexDirection: "row-reverse"}}>
          <div className="progress-bar bg-danger " role="progressbar" style={{width: `${Negative}%`}}  aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
       </div>
       <div className="hr_btn_div"></div>
       <div className="  d-flex"  style={{width: "45% " }}>
          <div className="progress-bar bg-success " role="progressbar" style={{width: `${Positive}%`}}  aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
       </div>
  
    </div>
 )
}