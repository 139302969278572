import React from 'react'
import "./style.css"
export default function TabelDelegateDetails({data,tableRef,IdName}) {
  return (
     <>
       <table id={IdName}  ref={tableRef} className="table table-hover  table-striped    ">
        <thead className='dark_tabel_head'>
            <tr>
                <th>Id</th>
                <th>Tax Total</th>
                <th>  Sub Total</th>
                <th>Final Total </th>
                <th>Type</th>
                <th>Created Date</th>
                <th>migration datetime </th>
                <th>created at</th>
                <th> updated at</th>
            </tr>
        </thead>
        <tbody>
           {
           data  && data.map((item) =>(
<tr key={item.id}>      
                <td>{item.id}</td>
                <td> {item.tax_total.toFixed(2)}</td>
                <td>{item.sub_total.toFixed(2)}</td>
                <td>{item.final_total.toFixed(2)}</td>
                <td>{item.type}</td>
                <td>{item.created_date}</td>
                <td>{item.migration_datetime}</td>
                <td>{item.created_at}</td>
                <td>{item.updated_at}</td>
                
            </tr>
           ))  
          } 
           
            
          
        </tbody>
   </table>
     </>
  )
}
