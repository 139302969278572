import React, { forwardRef } from "react";
import log from "../../assset/image/VODOLOGO.png";
import { BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import "./style.css";
import { FormatDataHook } from "../../hooks/formatDataHook";
import { FormatNumberHook } from "../../hooks/FormatNumber";
import { t } from "i18next";

const PdfTabelCompIvoice = forwardRef((props, ref) => {
  const [formatData] = FormatDataHook(true);
  const [langNumber] = FormatNumberHook();

  return (
    <>
      <div ref={ref}>
        <table className="table table-hover  table-striped    ">
          <thead>
            <tr>
              <td colSpan={9}>
                <div className="text-center ">
                  <img width={50} height={50} src={log} alt="" />
                  <h3>Vodo Company</h3>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-4">Name Company :</div>
                      <div className="col-8">Vodo</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-4">Date start :</div>
                      <div className="col-8">Vodo</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-4">Date start :</div>
                      <div className="col-8">Vodo</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-4">Date start :</div>
                      <div className="col-8">Vodo</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="dark_tabel_head">
              <th>{t("Id")}</th>
              <th>{t("Tax Total")}</th>
              <th>{t("Sub Total")}</th>
              <th>{t("Total")}</th>
              <th>{t("Type")}</th>
              <th>{t("Created Date")}</th>
              <th>{t("migration datetime")} </th>
              <th>{t("Created at")}</th>
              <th>{t("Updated at")}</th>
            </tr>
          </thead>
          <tbody>
            {props.data &&
              props.data.data &&
              props.data.data.journals.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td> {langNumber(item.tax_total)}</td>
                  <td>{langNumber(item.sub_total)}</td>
                  <td>{langNumber(item.final_total)}</td>
                  <td>{t(item.type)}</td>
                  <td>{item.created_date}</td>
                  <td>{formatData(item.migration_datetime)}</td>
                  <td>{formatData(item.created_at)}</td>
                  <td>{formatData(item.updated_at)}</td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={9}>
                <div className="row footer_Tabel_bdf py-2">
                  <div className="col-4">800 124 8000 | saib.com.sa</div>
                  <div className="col-4 text-center">notes</div>
                  <div className="col-4 ">
                    <span className="m-1">
                      <BsFacebook size={25} />
                    </span>
                    <span className="m-1">
                      <AiFillTwitterCircle size={25} />
                    </span>
                    <span className="m-1">
                      <AiFillInstagram size={25} />
                    </span>
                    <span className="m-1"> info@Vodo.com</span>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
});

export default PdfTabelCompIvoice;
