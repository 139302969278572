import React from "react";

export default function InputLogin({
  name,
  value,
  getvslueform,
  type,
  placeholder,
  errormassig,
}) {
  return (
    <>
      <div className="mb-3">
        <input
          value={value}
          name={name}
          onChange={(event) => getvslueform(event)}
          type={type}
          className={`form-control ${errormassig && "border border-danger"}`}
          placeholder={placeholder}
          aria-label={name}
        />
        <div>
          {errormassig && (
            <div className="alert alert-danger py-0 mt-1">
              {errormassig.map((item, index) => (
                <p key={index} className="m-0">
                  {item}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
