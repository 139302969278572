import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsListNested } from "react-icons/bs";
import "./style.css";
import { BsPersonCircle } from "react-icons/bs";
import SelectLang from "../../component/SelectLang/SelectLang";
import { useTranslation } from "react-i18next";
import { FiLogOut } from "react-icons/fi";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";

export default function Navbar() {
  const dispatch = useDispatch();

  const userData = useSelector((item) => item.login);

  const logout = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    // window.location.reload();
    dispatch({ type: "login", login: [] });
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg  shadow">
        <div className="container-xxl">
          <div className="navbar-brand" href="#">
            <span
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBackdrop"
              aria-controls="offcanvasWithBackdrop"
            >
              {" "}
              <BsListNested size={30} />
            </span>
          </div>

          <div className="d-flex">
            <div className="mx-2">
              <SelectLang />
            </div>
            <div className="  dropdown     ">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {userData && userData.data && userData.data.user.first_name}{" "}
                {userData && userData.data && userData.data.user.last_name}{" "}
                <BsPersonCircle size={30} color={"#2a2f38"} />
              </a>
              <ul
                className="dropdown-menu  text-center "
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li onClick={logout} className="dropdown-item  py-2  ">
                  {t("logout")}{" "}
                  <span className="icon_red">
                    {" "}
                    <FiLogOut color="eb3d34" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
