import React from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { RiFileExcel2Fill } from "react-icons/ri";
import PdfComponent from "../DownloadPdfTabel";

export default function BtnPdfExcelComp({
  nameFile,
  namComp,
  dataChart,
  children,
  compenTabel,
  orders,
  dataPrint,
}) {
  let datanow = new Date();
  let dataupdate = `${nameFile}_${datanow.getSeconds()}${datanow.getMinutes()}${datanow.getHours()}${datanow.getDate()}${datanow.getMonth()}${datanow.getFullYear()}`;
  return (
    <>
      <div className="d-flex m-1">
        <PdfComponent
          compenTabel={compenTabel}
          namComp={namComp}
          nameFile={dataupdate}
          dataChart={dataChart}
          orders={orders}
          dataPrint={dataPrint}
        >
          {compenTabel}
        </PdfComponent>
        <div className="div_tabel mx-2 ">
          <span
            className="pointer"
            onClick={() =>
              document.getElementById("test-table-xls-button").click()
            }
          >
            <RiFileExcel2Fill size={27} color={"green"} />
            <ReactHtmlTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="table-to-xls"
              filename={dataupdate}
              sheet="tablexls"
              buttonText={""}
            />
          </span>
        </div>
      </div>
      <div className="d-none">{children}</div>
    </>
  );
}
