import React, { forwardRef } from "react";
import "./style.css";
import HeaderPDF from "./HeaderPDF";
import FooterPDF from "./FooterPDF";
import HTabeleProduct from "../TabelComp/TabeleProduct/HTabeleProduct";
import TbodyProduct from "../TabelComp/TabeleProduct/TbodyProduct";
import { t } from "i18next";

const PdfTabelProdect = forwardRef((props, ref) => {
  return (
    <>
      <div ref={ref}>
        <table className="table  table-striped    ">
          <HTabeleProduct orders={props.orders}>
            <HeaderPDF>
              <div className="d-flex justify-content-between">
                <h5>
                  <span>{t("Inventory Secretary")} : </span>
                  {props.dataPrint?.name}
                </h5>
                <h5>
                  <span>{t("phone number")} : </span> {props.dataPrint?.mobile}
                </h5>
              </div>
            </HeaderPDF>
          </HTabeleProduct>

          {props.data && (
            <TbodyProduct data={props.data} orders={props.orders} />
          )}

          <tfoot>
            <FooterPDF />
          </tfoot>
        </table>
      </div>
    </>
  );
});

export default PdfTabelProdect;
