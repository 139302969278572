import React from "react";

export default function Tbody({ data, orders }) {
  return (
    <>
      <tbody>
        {data &&
          data.map((item) => (
            <tr className="pointer" key={item.id}>
              <td className="centered-cell">{item.id}</td>
              <td className="centered-cell"> {item.name}</td>
              <td className="centered-cell"> {item.reservation_num}</td>
              {orders !== "services" && (
                <td className="centered-cell"> {item.orders_num}</td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
}
