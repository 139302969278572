import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  unstable_HistoryRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import VODOLOGO from "../../assset/image/VODO_LOGO.png";
import "./style.css";
import { t } from "i18next";
import InputLogin from "../../component/InputLogin/InputLogin";
import { login, show_user_by_token } from "../../redux/action/action";
import SpinnerLoding from "../../component/SpinnerLoding/SpinnerLoding";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [massege, setmassege] = useState("");
  const [value, setvalue] = useState({
    email: "",
    password: "",
  });
  const [showForm, setshowForm] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("_token");
  // login by token in url
  useEffect(() => {
    if (token) {
      setshowForm(false);
      dispatch(show_user_by_token(token));
      sessionStorage.setItem("token", JSON.stringify(token));
    }
  }, []);

  const submituser = (event) => {
    event.preventDefault();
    dispatch(login(value));
  };
  const result = useSelector((state) => state.login);

  useEffect(() => {
    if (result && result.status) {
      searchParams.delete("_token");

      navigate("/");
      window.location.reload();
    } else if (result && result.status == false) {
      setshowForm(true);

      setmassege(result.message);

      setTimeout(() => {
        dispatch({ type: "login", login: [] });
        setmassege(null);
      }, 10000);
    }
  }, [result]);

  const path = window.location.origin;
  const getvslueform = (event) => {
    const name = event.target.name;
    const val = event.target.value;
    setvalue({
      ...value,
      [name]: val,
    });
  };
  return (
    <>
      <div className="login-page d-flex flex-column justify-content-center align-items-center">
        {showForm ? (
          <>
            <div className="container text-center ">
              <img width={200} className=" mt-5" src={VODOLOGO} alt="" />
            </div>
            <div className="container form-login mt-5">
              <form onSubmit={submituser} className="w-50 m-auto">
                {massege && (
                  <div test-id="masseg_erorr" className="alert alert-danger">
                    {massege}
                  </div>
                )}

                <InputLogin
                  value={value.email}
                  getvslueform={getvslueform}
                  type="email"
                  placeholder={t("email")}
                  name={"email"}
                  errormassig={
                    result.status === false &&
                    result.data.email &&
                    result.data.email
                  }
                />

                <InputLogin
                  value={value.password}
                  getvslueform={getvslueform}
                  type="password"
                  placeholder={t("password")}
                  name={"password"}
                  errormassig={
                    result.status === false &&
                    result.data.password &&
                    result.data.password
                  }
                />

                <button
                  disabled={value.email.length < 4 || value.password.length < 4}
                  className="btn mb-1 dark_btn w-100"
                  type="submit"
                >
                  {t("Login")}
                </button>
              </form>
            </div>{" "}
          </>
        ) : (
          <SpinnerLoding />
        )}
      </div>
    </>
  );
};
export default Login;
