import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  business_users_chart,
  specialists_services_chart,
  specialists_services_totals,
} from "../../redux/action/action";
import { useState } from "react";
import Paginated from "../../component/paginate/paginate";
import BtnPdfExcelComp from "../../component/btnPdfExcelComp/BtnPdfExcelComp";
import { FormatDataDayHook } from "../../hooks/formatDataHook";
import HeaderPages from "../../component/HeaderPages/HeaderPages";
import { FilterTimeHook } from "../../hooks/FilterTimeHook";
import FromToInputDate from "../../component/FromToInputDate/FromToInputDate";
import SpinnerLoding from "../../component/SpinnerLoding/SpinnerLoding";
import Top_Less_Limit from "../../component/Top_Less_Limit/Top_Less_Limit";
import TabelelSpecialistServices from "../TabelComp/TabelelSpecialistServices";
import i18next from "i18next";
import ChartBarAsideFullScreen from "../ChartBarAsideFullScreen/ChartBarAsideFullScreen";
import { t } from "i18next";

export default function SpecialistServices({ list_type, title, labelTabel }) {
  const [pageNumber, setNumberpage] = useState(1);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [filter_Top_Less_user, setfilter_Top_Less_user] = useState("all");
  const [Limit_Top_Less_user, setLimit_Top_Less_user] = useState(10);
  let lang = i18next.language;
  let lcoaction = window.location.pathname;

  useEffect(() => {
    despatch({ type: "business_users", business_users: [] });

    despatch({
      type: "specialists_services_totals",
      payload: [],
    });

    despatch({
      type: "specialists_services_chart",
      payload: [],
    });
  }, [lcoaction]);
  const [year, setYear, month, setMonth, Day, setDay, type, setType] =
    FilterTimeHook();
  const [formatDay] = FormatDataDayHook();
  const [full_screen, setfull_screen] = useState("");
  const despatch = useDispatch();
  const day = formatDay(Day);
  // data by pages

  let data = {
    page: pageNumber,
    year: year,
    month: month,
    day: day,
    date_filter_type: type,
    to_date: toDate,
    from_date: fromDate,
    data_type: filter_Top_Less_user,
    limit: Limit_Top_Less_user,
    result_type: "list",
    pagination: true,
    lang: lang,
  };
  useEffect(() => {
    if (data.lang && list_type) {
      despatch(specialists_services_totals(data, list_type));
    }
  }, [
    year,
    month,
    type,
    Day,
    pageNumber,
    filter_Top_Less_user,
    Limit_Top_Less_user,
    toDate,
    fromDate,
    lang,
  ]);
  useEffect(() => {
    data.result_type = "chart";
    data.pagination = false;
    if (data.lang && list_type) {
      despatch(specialists_services_chart(data, list_type));
    }
  }, [year, month, type, Day, toDate, fromDate, lang]);
  useEffect(() => {
    if (data && year && month && day && type) {
      despatch(business_users_chart("business_users_qty", data, false, "qty"));
    }
  }, [year, month, type, Day, filter_Top_Less_user, Limit_Top_Less_user]);
  const specialistsServicesTotals = useSelector(
    (state) => state.specialists_services_totals
  );

  const specialistsServicesChart = useSelector(
    (state) => state.specialists_services_chart
  );

  const tableRef = useRef(null);
  let label_chart = [];
  let reservation_num = [];
  let orders_num = [];
  if (specialistsServicesChart && specialistsServicesChart.status) {
    specialistsServicesChart.data.forEach((item) => {
      label_chart.push(item.name);
      list_type === "specialists" && orders_num.push(item.orders_num);
      reservation_num.push(item.reservation_num);
    });
  }
  const handlePageClick = (e) => {
    setNumberpage(e.selected + 1);
  };
  let nameFile = `${t("Reports")} ${t(title)}`;

  return (
    <>
      <section className="container-xxl hr_page  my-2 py-2">
        <HeaderPages
          type={type}
          setType={setType}
          Day={Day}
          setDay={setDay}
          title={title}
          setMonth={setMonth}
          setYear={setYear}
          year={year}
          month={month}
        >
          <div className="row">
            <div className="col-12  ">
              <FromToInputDate
                fromDate={fromDate}
                setfromDate={setfromDate}
                toDate={toDate}
                settoDate={settoDate}
                custoClass={"d-flex flex-wrap"}
              />
            </div>
          </div>
        </HeaderPages>
        <div className="main_back_page">
          {specialistsServicesChart && specialistsServicesChart.status ? (
            <div className="row">
              {list_type === "specialists" && (
                <div className="col-12 col-lg-6">
                  <div className="  position-relative ">
                    <ChartBarAsideFullScreen
                      title={`Number of Invoices`}
                      full_screen={full_screen}
                      NameSection={"final_total"}
                      created_date={label_chart}
                      arrayvalue={reservation_num}
                      setfull_screen={setfull_screen}
                      name={"Invoices"}
                    />
                  </div>
                </div>
              )}
              <div
                className={`col-12 ${
                  list_type === "specialists" && "col-lg-6"
                }`}
              >
                <div className="  position-relative">
                  <ChartBarAsideFullScreen
                    title={`Number of Reservations`}
                    full_screen={full_screen}
                    NameSection={"final_total"}
                    created_date={label_chart}
                    arrayvalue={reservation_num}
                    setfull_screen={setfull_screen}
                    name={"Reservations"}
                  />
                </div>
              </div>
            </div>
          ) : (
            <SpinnerLoding />
          )}
          <h3 className="text-center mt-5 mb-0">{t(labelTabel)}</h3>
          <div>
            {specialistsServicesChart && specialistsServicesChart.status && (
              <div className="d-flex justify-content-center  mt-3">
                <Top_Less_Limit
                  filter_Top_Less_user={filter_Top_Less_user}
                  setfilter_Top_Less_user={setfilter_Top_Less_user}
                  Limit_Top_Less_user={Limit_Top_Less_user}
                  setLimit_Top_Less_user={setLimit_Top_Less_user}
                  all={"All"}
                  less={"Less"}
                  top={"Top"}
                />
              </div>
            )}

            {specialistsServicesTotals && specialistsServicesTotals.status ? (
              <div>
                <BtnPdfExcelComp
                  nameFile={nameFile}
                  namComp={"SpecialistServices"}
                  dataChart={specialistsServicesTotals.data}
                  orders={list_type}
                >
                  {specialistsServicesTotals &&
                    specialistsServicesTotals.status && (
                      <TabelelSpecialistServices
                        data={specialistsServicesTotals.data}
                        IdName={"table-to-xls"}
                        tableRef={tableRef}
                        orders={list_type}
                      />
                    )}
                </BtnPdfExcelComp>

                {/* tabel to view */}

                <div className="    overflow-auto Light_back">
                  {specialistsServicesTotals &&
                    specialistsServicesTotals.status && (
                      <TabelelSpecialistServices
                        customClass={"dark_tabel_head"}
                        data={specialistsServicesTotals.data}
                        tableRef={tableRef}
                        orders={list_type}
                      />
                    )}
                </div>
              </div>
            ) : (
              <SpinnerLoding />
            )}
            <div className="d-felx d-flex  align-items-center  justify-content-center">
              {specialistsServicesTotals &&
                specialistsServicesTotals.status && (
                  <Paginated
                    handlePageClick={handlePageClick}
                    pageCounts={specialistsServicesTotals.pages_num}
                  />
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
