import React, { useDeferredValue } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import SpinnerLoding from "../../component/SpinnerLoding/SpinnerLoding";
import BtnPdfExcelComp from "../../component/btnPdfExcelComp/BtnPdfExcelComp";
import Paginated from "../../component/paginate/paginate";
import i18next, { t } from "i18next";
import { useNavigate, useParams } from "react-router";
import { detailsProduct, products } from "../../redux/action/action";
import TabeleDetailsProduct from "../../component/TabelComp/TabeleDetailsProduct";
import ChartBarAsideFullScreen from "../../component/ChartBarAsideFullScreen/ChartBarAsideFullScreen";
import Mapping from "../../component/mapping/Mapping";
import ModalComp from "../../component/ModalComp/ModalComp";
import SearchAndSelect from "../../component/SearchAndSelect/SearchAndSelect";
export default function DetailsProduct() {
  const [pageNumber, setNumberpage] = useState(1);
  const [productID, setproductID] = useState("");
  const [changeProduct, setchangeProduct] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  let lang = i18next.language;
  let param = useParams();
  const despatch = useDispatch();
  const navgite = useNavigate();
  useEffect(() => {
    if (param.id) {
      setproductID(param.id);
    } else {
      despatch(products("", lang));
    }
  }, [param.id]);
  const deferredsearchTerm = useDeferredValue(searchTerm);

  useEffect(() => {
    if (deferredsearchTerm) {
      despatch(products(deferredsearchTerm, lang));
    } else {
      despatch({ type: "products", payload: [] });
    }
  }, [deferredsearchTerm, lang]);

  const buttonModal = useRef();
  useEffect(() => {
    despatch(detailsProduct("detailsProduct", productID, lang));
  }, [lang, productID]);

  useEffect(() => {
    despatch(
      detailsProduct("detailsProduct_withPAges", productID, lang, pageNumber)
    );
  }, [lang, pageNumber, productID]);
  const [full_screen, setfull_screen] = useState("");

  const details_Product = useSelector(
    (state) => state.detailsProduct_withPAges
  );
  const AlldetailsProduct = useSelector((state) => state.detailsProduct);
  const tableRef = useRef(null);

  const handlePageClick = (e) => {
    setNumberpage(e.selected + 1);
  };
  let nameFile = `${t("Product")}_${
    details_Product.status && details_Product.data.product.name
  }`;
  let label_chart = [];
  let value_num = [];
  let MarketPlace = [];
  if (AlldetailsProduct && AlldetailsProduct.status) {
    let updatadata = AlldetailsProduct.data.inventories;
    updatadata.sort((a, b) => a.stock - b.stock).reverse();
    updatadata.forEach((item) => {
      label_chart.push(item.inventory_name);
      value_num.push(item.stock);
      item.latitude &&
        MarketPlace.push({
          lat: item.latitude,
          id: item.inventory_id,
          lng: item.longitude,
          name: `${item.inventory_name} : ${item.stock} `,
        });
    });
  }
  const hendling_action_marker = (id) => {
    let dataFilter = AlldetailsProduct?.data?.inventories.filter(
      (item) => item.inventory_id == id
    );
    setchangeProduct(...dataFilter);
    buttonModal.current.click();
  };
  const searchResults = useSelector((state) => state.products);
  const change_product = (data) => {
    navgite(`/DetailsProduct/${data.id}`);
    despatch({ type: "products", payload: [] });
    setSearchTerm("");
  };
  // to set 1st product in list product
  useEffect(() => {
    if (searchResults.status && searchResults.data?.length > 0 && !param.id) {
      navgite(
        `/DetailsProduct/${
          searchResults.data[searchResults.data.length - 1].id
        }`
      );
      despatch({ type: "products", payload: [] });
    }
  }, [searchResults]);
  return (
    <>
      <ModalComp buttonModal={buttonModal}>
        <div className="row">
          <div className="col-12 text-center">
            {details_Product.status && details_Product.data.product.name}
            <img
              src={details_Product.status && details_Product.data.product.image}
              alt=""
              width={40}
              height={40}
              className="mx-1"
            />
          </div>
          <div className="col-6">
            {t("inventory name")} : {changeProduct?.inventory_name}
          </div>
          <div className="col-6">
            {t("stock")} : {changeProduct.stock}
          </div>
        </div>
      </ModalComp>
      <section className="container-xxl hr_page  my-2 py-2">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="">
            <h2 className="title_Page_border">
              {t("Product")}:{" "}
              {details_Product.status && details_Product.data.product.name}
              <img
                src={
                  details_Product.status && details_Product.data.product.image
                }
                alt=""
                width={40}
                height={40}
                className="mx-1"
              />
            </h2>
          </div>
          <div>
            {
              <SearchAndSelect
                searchResults={searchResults.status && searchResults.data}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                change_product={change_product}
                placeholder={"search on products"}
              />
            }
          </div>
        </div>
        <div className="main_back_page">
          {AlldetailsProduct && AlldetailsProduct.status ? (
            <div className="  position-relative my-5">
              <ChartBarAsideFullScreen
                title={`Product stock in all  inventories`}
                full_screen={full_screen}
                NameSection={"final_total"}
                created_date={label_chart}
                arrayvalue={value_num}
                setfull_screen={setfull_screen}
                name={"Reservations"}
              />
            </div>
          ) : (
            <SpinnerLoding />
          )}
          <div className=" map_div mt-3  ">
            <h2 className="text-center my-3"> {t("Inventories location")}</h2>
            {AlldetailsProduct && AlldetailsProduct.status ? (
              <Mapping
                MarketPlace={MarketPlace}
                hendling_action_marker={hendling_action_marker}
              />
            ) : (
              <SpinnerLoding />
            )}
          </div>
          <br />
          <div className="mt-5">
            {AlldetailsProduct && AlldetailsProduct.status && (
              <div>
                <BtnPdfExcelComp
                  nameFile={nameFile}
                  namComp={"ProductListDetails"}
                  dataChart={AlldetailsProduct.data.inventories}
                >
                  <TabeleDetailsProduct
                    data={AlldetailsProduct.data.inventories}
                    IdName={"table-to-xls"}
                    tableRef={tableRef}
                  />
                </BtnPdfExcelComp>
              </div>
            )}
            {details_Product && details_Product.status ? (
              <div className="    overflow-auto Light_back">
                <TabeleDetailsProduct
                  customClass="dark_tabel_head"
                  data={details_Product.data.inventories.data}
                  tableRef={tableRef}
                />
              </div>
            ) : (
              <SpinnerLoding />
            )}
            <div className="d-felx d-flex  align-items-center  justify-content-center ">
              {details_Product && details_Product.status && (
                <Paginated
                  handlePageClick={handlePageClick}
                  pageCounts={details_Product.data.inventories?.last_page}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
