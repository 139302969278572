import React from "react";
import HeadTabel from "./HeadTabel";
import Tbody from "./Tbody";

export default function TabelelSpecialistServices({
  data,
  tableRef,
  IdName,
  customClass,
  setinvoices,
  orders,
}) {
  return (
    <>
      <table
        id={IdName}
        ref={tableRef}
        className="table table-hover  table-striped    "
      >
        <HeadTabel customClass={customClass} orders={orders} />
        <Tbody orders={orders} data={data} setinvoices={setinvoices} />
      </table>
    </>
  );
}
