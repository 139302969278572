import React from "react";
import "./style.css"
import { AiOutlineFullscreen,AiOutlineFullscreenExit } from "react-icons/ai";

const ButtonFullScreen =({full_screen,setfull_screen,NameType}) =>{

    return(<>
    { NameType !== full_screen ?  <div className="text-end icon_full_screen" onClick={() => setfull_screen( NameType)}> <AiOutlineFullscreen size={25}/></div>
          : <div className="text-end icon_exit_full_screen" onClick={() => setfull_screen("")}> <AiOutlineFullscreenExit size={30}/></div>
       }
    </>)
}
export default ButtonFullScreen