import React from "react";
import "./style.css";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  business_users_Totals,
  business_users_chart,
} from "../../redux/action/action";
import { useState } from "react";
import Paginated from "../paginate/paginate";
import Tabel_Customers_Suppliers from "../TabelComp/Tabel_Customers_Suppliers";
import ChartBarOneWithFullScreenCopm from "../ChartBarOneWithFullScreenCopm/ChartBarOneWithFullScreenCopm";
import BtnPdfExcelComp from "../btnPdfExcelComp/BtnPdfExcelComp";
import { FormatDataDayHook, FormatDataHook } from "../../hooks/formatDataHook";
import HeaderPages from "../HeaderPages/HeaderPages";
import { FilterTimeHook } from "../../hooks/FilterTimeHook";
import Mapping from "../mapping/Mapping";
import FromToInputDate from "../FromToInputDate/FromToInputDate";
import FormSwitchCheck from "../formSwitchCheck/FormSwitchCheck";
import FormCheckInput from "../formSwitchCheck/FormCheckInput";
import SpinnerLoding from "../SpinnerLoding/SpinnerLoding";
import ChartDountTotals from "../chartDountTotals/chartDountTotals";
import Top_Less_Limit from "../Top_Less_Limit/Top_Less_Limit";
import { t } from "i18next";
import ModalComp from "../ModalComp/ModalComp";
export default function Customers_Suppliers_Comp({ title, user_type }) {
  const [pageNumber, setNumberpage] = useState(1);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [checkFilter, setcheckFilter] = useState("All_Data");
  const [filter_Top_Less_user, setfilter_Top_Less_user] = useState("all");
  const [Limit_Top_Less_user, setLimit_Top_Less_user] = useState(10);
  const [formData, setFormData] = useState({});
  const [detailsToMap, setdetailsToMap] = useState("");

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.value]: e.target.checked,
    });
  };
  let lcoaction = window.location.pathname;

  useEffect(() => {
    despatch({ type: "business_users", business_users: [] });

    despatch({
      type: "business_users_locations",
      business_users_locations: [],
    });

    despatch({
      type: "business_users_qty",
      business_users_qty: [],
    });

    despatch({
      type: "business_users_Totals",
      business_users_Totals: [],
    });
  }, [lcoaction]);
  const [year, setYear, month, setMonth, Day, setDay, type, setType] =
    FilterTimeHook();
  const [formatDay] = FormatDataDayHook();
  const [full_screen, setfull_screen] = useState("");
  const despatch = useDispatch();
  const day = formatDay(Day);
  // data by pages
  const filterdataUser = (nameAction) => {
    if (checkFilter === "All_Data") {
      return "";
    } else if (checkFilter === "With_this_data") {
      return formData[nameAction] ? true : "";
    } else if (checkFilter === "Withou_this_data") {
      return formData[nameAction] ? false : "";
    }
  };

  let data = {
    user_type: user_type,
    status: filterdataUser("status"),
    credit_limit: filterdataUser("credit_limit"),
    delivery_address: filterdataUser("delivery_address"),
    billing_address: filterdataUser("billing_address"),
    business_identifier: filterdataUser("business_identifier"),
    company_name: filterdataUser("company_name"),
    postcode: filterdataUser("postcode"),
    state: filterdataUser("state"),
    latitude: filterdataUser("latitude"),
    longitude: filterdataUser("longitude"),
    district: filterdataUser("district"),
    city: filterdataUser("city"),
    page: pageNumber,
    year: year,
    month: month,
    day: day,
    date_filter_type: type,
    to_date: toDate,
    data_type: "all",
    email: filterdataUser("email"),
    phone: filterdataUser("phone"),
    address1: filterdataUser("address1"),
    address2: filterdataUser("address2"),
    country_id: filterdataUser("country_id"),
    vat_number: filterdataUser("vat_number"),
    currency: filterdataUser("currency"),
    from_date: fromDate,
    limit: Limit_Top_Less_user,
  };

  useEffect(() => {
    if (
      data &&
      year &&
      month &&
      day &&
      type &&
      filter_Top_Less_user &&
      user_type
    ) {
      despatch(
        business_users_chart("business_users", data, true, filter_Top_Less_user)
      );
    }
  }, [
    year,
    month,
    type,
    Day,
    pageNumber,
    checkFilter,
    formData,
    filter_Top_Less_user,
    Limit_Top_Less_user,
  ]);

  useEffect(() => {
    if (data && year && month && day && type && user_type) {
      despatch(
        business_users_chart("business_users_locations", data, false, "custom")
      );
      despatch(business_users_chart("business_users_qty", data, false, "qty"));
      despatch(business_users_Totals(data));
    }
  }, [
    year,
    month,
    type,
    Day,
    checkFilter,
    formData,
    filter_Top_Less_user,
    Limit_Top_Less_user,
  ]);
  const businessUsers = useSelector((state) => state.business_users);

  const businessUsersQty = useSelector((state) => state.business_users_qty);
  const businessUsersTotals = useSelector(
    (state) => state.business_users_Totals
  );
  const businessUsersLocations = useSelector(
    (state) => state.business_users_locations
  );
  const businessUsersAllData = useSelector(
    (state) => state.business_users_all_data
  );

  const tableRef = useRef(null);
  let created_date_user = [];
  let qty_create_user = [];
  let MarketPlace = [];

  if (businessUsersQty && businessUsersQty.status) {
    businessUsersQty.data.customers.forEach((item) => {
      created_date_user.push(item.created_date);
      qty_create_user.push(item.qty);
    });
  }
  if (businessUsersLocations && businessUsersLocations.status) {
    businessUsersLocations.data.customers.forEach((item) => {
      MarketPlace.push({
        lat: item.latitude,
        lng: item.longitude,
        name: item.first_name,
        id: item.id,
      });
    });
  }
  const handlePageClick = (e) => {
    setNumberpage(e.selected + 1);
  };
  const buttonModal = useRef();
  let nameFile = `${t("Reports")} ${t(title)}`;
  const totalisChart = [];
  if (businessUsersTotals && businessUsersTotals.status) {
    let data = businessUsersTotals.data;
    for (let key in data) {
      if (key.startsWith("has_")) {
        const hasKey = key;
        const notKey = `has_no_${key.slice(4)}`;
        const name_1 = key.replace(/_/g, " ");
        const name_2 = notKey.replace(/_/g, " ");

        data[notKey] &&
          totalisChart.push({
            key_has: hasKey,
            key_not: notKey,
            name_1,
            name_2,
            value_1: data[hasKey],
            value_2: data[notKey],
          });
      }
    }
  }
  const hendling_action_marker = (id) => {
    let dataFilter = businessUsersAllData?.data?.customers.filter(
      (item) => item.id == id
    );
    setdetailsToMap(...dataFilter);
    buttonModal.current.click();
  };
  return (
    <>
      <ModalComp buttonModal={buttonModal}>
        <div className="row">
          <h3 className="col-12 text-center py-1">
            {detailsToMap?.first_name}
          </h3>
          <div className="col-6 text-center">
            {t("address")} : {detailsToMap?.address}
          </div>
          <div className="col-6 text-center">
            {t("address")} : {detailsToMap?.address}
          </div>
        </div>
      </ModalComp>
      <section className="container-xxl hr_page  my-2 py-2">
        <HeaderPages
          type={type}
          setType={setType}
          Day={Day}
          setDay={setDay}
          title={title}
          setMonth={setMonth}
          setYear={setYear}
          year={year}
          month={month}
        >
          <div className="row">
            <div className="col-12 col-md-4">
              <FromToInputDate
                fromDate={fromDate}
                setfromDate={setfromDate}
                toDate={toDate}
                settoDate={settoDate}
              />
            </div>
            <div className="col-12 col-md-8 ">
              <form
                onChange={(e) => setcheckFilter(e.target.value)}
                className="py-2"
                value={checkFilter}
              >
                <div className="d-flex justify-content-center flex-wrap">
                  <FormCheckInput
                    checkFilter={checkFilter}
                    label={"All Data"}
                    value={"All_Data"}
                  />
                  <FormCheckInput
                    checkFilter={checkFilter}
                    label={"With this data"}
                    value={"With_this_data"}
                  />
                  <FormCheckInput
                    checkFilter={checkFilter}
                    label={"Without this data"}
                    value={"Withou_this_data"}
                  />
                </div>
              </form>
              <form onChange={handleFormChange}>
                <div className="row shadow py-2 w-100 m-auto">
                  <FormSwitchCheck value="email" />
                  <FormSwitchCheck value="vat_number" />
                  <FormSwitchCheck value="currency" />
                  <FormSwitchCheck value="district" />
                  <FormSwitchCheck value="city" />
                  <FormSwitchCheck value="delivery_address" />
                  <FormSwitchCheck value="status" />
                  <FormSwitchCheck value="company_name" />
                  <FormSwitchCheck value="postcode" />
                  <FormSwitchCheck value="business_identifier" />
                  <FormSwitchCheck value="billing_address" />
                  <FormSwitchCheck value="credit_limit" />
                  <FormSwitchCheck value="state" />
                  <FormSwitchCheck value="longitude" />
                  <FormSwitchCheck value="latitude" />
                  <FormSwitchCheck value="country_id" />
                  <FormSwitchCheck value="address2" />
                  <FormSwitchCheck value="address1" />
                  <FormSwitchCheck value="phone" />
                </div>
              </form>
            </div>
          </div>
        </HeaderPages>
        <div className="main_back_page">
          {businessUsersQty && businessUsersQty.status ? (
            <div className="row">
              <div className="col-12  position-relative">
                <ChartBarOneWithFullScreenCopm
                  title={`created date ${title}`}
                  full_screen={full_screen}
                  NameSection={"final_total"}
                  created_date={created_date_user}
                  arrayvalue={qty_create_user}
                  setfull_screen={setfull_screen}
                />
                {/* <MyLoader setHight={"300"}/> */}
              </div>
            </div>
          ) : (
            <SpinnerLoding />
          )}
          {businessUsersTotals && businessUsersTotals.status ? (
            <div className="row">
              <h2 className="mt-2 text-center">
                {title} {t("data statistics")}
              </h2>
              {totalisChart &&
                totalisChart.map((item) => (
                  <div key={item.key_has} className="col-12 col-sm-6 col-md-3 ">
                    <ChartDountTotals
                      setfull_screen={setfull_screen}
                      full_screen={full_screen}
                      NameSection={item.name_1}
                      staticColor={true}
                      title={item.name_1}
                      arraylabel={[
                        `  ${item.value_1} : Available`,
                        `  ${item.value_2}   : N/A  `,
                      ]}
                      arrayvalue={[item.value_1, item.value_2]}
                    />
                  </div>
                ))}
            </div>
          ) : (
            <SpinnerLoding />
          )}

          <div className=" map_div mt-3">
            {businessUsersLocations && businessUsersLocations.status ? (
              <Mapping
                MarketPlace={MarketPlace}
                hendling_action_marker={hendling_action_marker}
              />
            ) : (
              <SpinnerLoding />
            )}
          </div>

          <div>
            {businessUsers && businessUsers.status ? (
              <div>
                <BtnPdfExcelComp
                  nameFile={nameFile}
                  namComp={"Delegates"}
                  dataChart={businessUsers.data.customers.data}
                >
                  {businessUsers && businessUsers.status && (
                    <Tabel_Customers_Suppliers
                      data={businessUsers.data.customers.data}
                      IdName={"table-to-xls"}
                      tableRef={tableRef}
                    />
                  )}
                </BtnPdfExcelComp>
                <div className="d-flex justify-content-center  ">
                  <Top_Less_Limit
                    filter_Top_Less_user={filter_Top_Less_user}
                    setfilter_Top_Less_user={setfilter_Top_Less_user}
                    Limit_Top_Less_user={Limit_Top_Less_user}
                    setLimit_Top_Less_user={setLimit_Top_Less_user}
                    all={"All clients"}
                    less={"Less clients"}
                    top={"Top clients"}
                  />
                </div>

                <div className="    overflow-auto Light_back">
                  {businessUsers && businessUsers.status && (
                    <Tabel_Customers_Suppliers
                      data={businessUsers.data.customers.data}
                      tableRef={tableRef}
                    />
                  )}
                </div>
              </div>
            ) : (
              <SpinnerLoding />
            )}
            <div className="d-felx d-flex  align-items-center  justify-content-center">
              {businessUsers && businessUsers.status && (
                <Paginated
                  handlePageClick={handlePageClick}
                  pageCounts={businessUsers.data.customers.last_page}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
