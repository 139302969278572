import React from "react";

export default function ModalComp({ buttonModal, children }) {
  return (
    <>
      <button
        ref={buttonModal}
        type="button"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#ModalComp"
      ></button>

      <div
        className="modal fade"
        id="ModalComp"
        tabIndex="-1"
        aria-labelledby="ModalCompLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-body">
              <div
                style={{ right: "10px", top: " 8px" }}
                className="text-end position-absolute "
              >
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
