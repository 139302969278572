import React from "react";
import { FormatDataHook } from "../../../hooks/formatDataHook";
import { useNavigate } from "react-router";
import { FiExternalLink } from "react-icons/fi";
import "../style.css";
export default function TbodyDetailsProduct({ data, orders }) {
  return (
    <>
      <tbody>
        {data &&
          data.map((item) => (
            <tr className="pointer text-center" key={item.inventory_id}>
              <td>{item.inventory_id}</td>

              <td>{item.inventory_name}</td>

              <td>{item.stock}</td>
            </tr>
          ))}
      </tbody>
    </>
  );
}
