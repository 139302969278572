import React from "react";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";

const ArrowToggleIcon = () => {
  const [Active, setActive] = useState(true);
  return (
    <>
      <span className="ArrowToggleIcon" onClick={() => setActive(!Active)}>
        {Active ? (
          <MdKeyboardArrowRight size={25} />
        ) : (
          <IoIosArrowDown size={20} />
        )}
      </span>
    </>
  );
};
export default ArrowToggleIcon;
