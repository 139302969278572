
import React from "react";
import { useTranslation } from "react-i18next";
import { FormatNumberHook } from "../../hooks/FormatNumber";
import ArrowToggleIcon from "../ArrowToggleIcon/ArrowToggleIcon";
import ProgresBarSheet from "../ProgresBarSheet/ProgresBarSheet";



const RowBalanceSheet  =({item,classMargin}) =>{
  const { t, i18n } = useTranslation();
  const [formatNumnber] = FormatNumberHook()
const lang = i18n.language;
 
let name = lang == "en" || item.arabic_name == null ? item.name ?item.name:  item.account_name:item.arabic_name
    return(
    <div className="row">
    <div className="col-4"><div className={`b_button_item NotWrap_Overvew_auto ${classMargin} `}>
    {item.subgroup  && item.subgroup.length > 0 ?

     <span data-bs-toggle="collapse"     aria-expanded="true" data-bs-target={`#Item${item.id}`}>    <ArrowToggleIcon/>  </span> 
    : item.account && item.account.length > 0 && <span data-bs-toggle="collapse"     aria-expanded="true" data-bs-target={`#Item${item.id}`}>    <ArrowToggleIcon/>  </span> 
    }
     {name }</div> </div>
   <div className="col-2 "> <div className="b_button_item NotWrap_Overvew_auto"> {`${formatNumnber(745.4)} ${t("K")}`}</div></div>
   <div className="col-2 "> <div className="b_button_item NotWrap_Overvew_auto"> {`${formatNumnber(745.4)} ${t("K")}`}</div></div>
   <div className="col-2"><div className="b_button_item">  <ProgresBarSheet Negative={70} Positive={0}/>  </div> </div>
   <div className="col-2"><div className="b_button_item text_red">  -70% </div> </div>
   </div>
  )}
  export default RowBalanceSheet;