import { t } from "i18next";
import React from "react";
import Chart from "react-apexcharts";

export default function ChartBarCountingMax({
  fullWidth,
  valueHight,
  final_total,
  created_date,
  sub_total,
  tax_total,
}) {
  let FullWidth = fullWidth ? fullWidth : null;
  const filterDataChart = (data) => {};
  const data = {
    options: {
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            total: {
              enabled: false,
              // offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
                color: "#000",
              },
            },
          },
        },
      },

      chart: {
        stacked: true,

        stackType: FullWidth,
        background: "none",
        id: "basic-bar",
        events: {
          click: function (event, chartContext, config) {
            filterDataChart(config.dataPointIndex);
          },
        },
      },
      xaxis: {
        categories: created_date,
      },
      colors: ["#E83C33", "#ff8d87", "#96231d"],
      yaxis: {
        show: true,
      },
    },

    series: [
      {
        name: t("Tax Total"),
        data: tax_total,
      },
      {
        name: t("Sub Total"),
        data: sub_total,
      },
      {
        name: t("Total"),
        data: final_total,
      },
    ],
  };

  return (
    <>
      <Chart
        options={data.options}
        series={data.series}
        type="bar"
        height={valueHight ? valueHight : 400}
        width="100%"
      />
    </>
  );
}
