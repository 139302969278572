import React from "react";
import ChartDonut from "../charts/ChartDonut";
import ButtonFullScreen from "../buttonFullScreen/ButtonFullScreen";
import ChartBarCounting from "../charts/ChartBarCounting";
import { t } from "i18next";
export default function ChartDountTotals({
  full_screen,
  arraylabel,
  setfull_screen,
  arrayvalue,
  title,
  valueHight,
  NameSection,
  staticColor,
}) {
  return (
    <div
      className={`shadow m-1  py-3 ${
        NameSection == full_screen && "full_screen"
      } `}
    >
      <ButtonFullScreen
        NameType={NameSection}
        full_screen={full_screen}
        setfull_screen={setfull_screen}
      />
      <h4 className="text-center"> {t(title)} </h4>
      <ChartDonut
        title={" Sub Total"}
        full_screen={full_screen}
        NameSection={"sub_total"}
        arraylabel={arraylabel}
        arrayvalue={arrayvalue}
        setfull_screen={setfull_screen}
        valueHight={NameSection == full_screen ? "95%" : valueHight}
        staticColor={staticColor}
      />
    </div>
  );
}
