import React from "react";
import Chart from "react-apexcharts";

const ChartBarWLiner = () => {
  const data = {
    options: {
      chart: {
        height: 350,
        type: "line",
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#99C2A2", "#C5EDAC", "#66C7F4"],
      series: [
        {
          name: "Column A",
          type: "column",
          data: [21.1, 23, 33.1, 34, 44.1, 44.9, 56.5, 58.5],
        },
        {
          name: "Column B",
          type: "column",
          data: [10, 19, 27, 26, 34, 35, 40, 38],
        },
        {
          name: "Line C",
          type: "line",
          data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
        },
      ],
      stroke: {
        width: [4, 4, 4],
      },
      plotOptions: {
        bar: {
          columnWidth: "20%",
        },
      },
      xaxis: {
        categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
      },
      yaxis: [
        {
          seriesName: "Column A",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
        },
        {
          seriesName: "Column A",
          show: false,
        },
        {
          opposite: true,
          seriesName: "Line C",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
        },
      ],
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false,
        },
      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40,
      },
    },

    series: [
      {
        type: "line",
        data: [1, 2, 3, 4],
      },
      {
        type: "column",
        data: [2, 3, 4, 5],
      },
      {
        type: "column",
        data: [2, 3, 4, 5],
      },
    ],
  };

  return (
    <Chart
      options={data.options}
      series={data.series}
      type="bar"
      height="400px"
      width="100%"
    />
  );
};
export default ChartBarWLiner;
