import React, { forwardRef } from "react";

import "./style.css";
import { FormatDataHook } from "../../hooks/formatDataHook";
import HeaderPDF from "./HeaderPDF";
import FooterPDF from "./FooterPDF";

const PdfTabel_Customers_Suppliers = forwardRef((props, ref) => {
  const [formatData] = FormatDataHook(true);
  const [formatWithOutNumber] = FormatDataHook(false);

  return (
    <>
      <div ref={ref}>
        <table className="table  table-striped    ">
          <thead>
            <HeaderPDF />
            <tr className="text-center">
              <th>Id</th>
              <th> name</th>
              <th> company name</th>
              <th>phone </th>
              <th>Created Date</th>
              <th>address </th>
              <th> invices</th>
            </tr>
          </thead>
          <tbody>
            {props.data &&
              props.data.map((item) => (
                <tr className="pointer" key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    {" "}
                    {item.first_name} {item.last_name}{" "}
                  </td>
                  <td>{item.company_name}</td>
                  <td>{item.phone}</td>

                  <td>{formatWithOutNumber(item.created_at)}</td>
                  <td>{item.address1}</td>

                  <td>
                    {" "}
                    <button
                      // onClick={() => go_to_details_delegate(item.id)}
                      className="btn btn-outline-danger"
                    >
                      {item.invoices_count}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <FooterPDF />
          </tfoot>
        </table>
      </div>
    </>
  );
});

export default PdfTabel_Customers_Suppliers;
