import React from 'react'
import HeaderPages from '../../component/HeaderPages/HeaderPages'
 
export default function HistoricalTrends() {
  return (
    <>
    <section className='container-xxl'>
        <HeaderPages  title={"Historical Trends"}  >
       </HeaderPages>

    </section>
    </>
  )
}
