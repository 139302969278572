import React from "react";
import { useTranslation } from "react-i18next";

export default function SelectInput({ onHendelSelect, data, value }) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <select
        onChange={(e) => onHendelSelect(e.target.value)}
        className="form-select my-2 mt-3 m-sm-0"
        aria-label="Default select example"
        value={value && value}
      >
        {data &&
          data.map((item) => (
            <option key={item.id} value={item.value ? item.value : item.id}>
              {t(item.name)}
            </option>
          ))}
      </select>
    </>
  );
}
