import React from 'react'
import LiInputRadio from '../filterTime/LiInputRadio'

export default function FormFilterChart() {
  return (
     <>
       <div className="form_filter_chart px-1">
                  <h5 className="m-0  ">tital</h5>
                  <div className="form_filter  ">
                    <form>
                      <ul className="donate-now text-center  mt-0">
                      <LiInputRadio value={"2019"} nameID={"testa"} name={"year"} labelName={"Last 12m"}/>
                      <LiInputRadio value={"2020"} nameID={"testa"} name={"year"} labelName={"CY"}/>
                      <LiInputRadio value={"2021"} nameID={"testa"} name={"year"} labelName={"PY"}/>
                      <LiInputRadio value={"2022"} nameID={"testa"} name={"year"} labelName={"All"}/>
                      </ul>
                    </form>
                  </div>
                </div>
     </>
  )
}
