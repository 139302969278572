import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
export default function FooterPDF() {
  const { t, i18n } = useTranslation();
  let lang = i18n.language;
  const userData = useSelector((item) => item.login);
  const currentDateTime = new Date();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };

  const formattedDateTime = currentDateTime.toLocaleString(
    lang == "en" ? "en-US" : "ar-Sr",
    options
  );
  return (
    <>
      {userData.status && (
        <tr className="footer_Tabel_bdf  py-2">
          <td colSpan={9}>
            <div className=" d-flex justify-content-between">
              <p className="m-0">
                {t("Created by")} :
                <b>
                  {" "}
                  {userData.data.user.first_name} {userData.data.user.last_name}
                </b>
              </p>
              <p className="m-0">
                {" "}
                {t("Date of creation")} :<b> {formattedDateTime}</b>
              </p>
            </div>
            <div className="   text-center">
              {" "}
              {userData?.data.business?.address}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
