import React from "react";
import SpecialistServices from "../../component/Specialists_Services/SpecialistServices";

export default function Specialists() {
  return (
    <>
      <SpecialistServices
        list_type={"specialists"}
        title={"Specialists"}
        labelTabel={"specialists vs. Reservations and invoices"}
      />
    </>
  );
}
