import React from "react";
import "./style.css";
export default function FormSwitchCheck({ value }) {
  return (
    <div className="col col-12 col-sm-4">
      <div className="form-check form-switch  ">
        <input
          className="form-check-input"
          type="checkbox"
          id={value}
          value={value}
        />
        <label className="form-check-label" htmlFor={value}>
          {value}
        </label>
      </div>
    </div>
  );
}
