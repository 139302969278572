import React from "react";
import HtabelInventories from "./HtabelInventories";
import TbodyInventories from "./TbodyInventories";

export default function TabelelInventories({
  data,
  tableRef,
  IdName,
  customClass,
  setinvoices,
  orders,
}) {
  return (
    <>
      <table
        id={IdName}
        ref={tableRef}
        className="table table-hover  table-striped    "
      >
        <HtabelInventories customClass={customClass} orders={orders} />
        <TbodyInventories
          orders={orders}
          data={data}
          setinvoices={setinvoices}
        />
      </table>
    </>
  );
}
