import React from "react";
import "./style.css";
import ChartGradient from "../../component/charts/ChartGradient";
import ChartBar from "../../component/charts/ChartBar";
import ChartDonut from "../../component/charts/ChartDonut";
import { FaUsers, FaUser } from "react-icons/fa";
import { RiArrowUpSFill } from "react-icons/ri";
import HeaderPages from "../../component/HeaderPages/HeaderPages";

export default function HrPage() {
  return (
    <>
      <section className="container-xxl hr_page  my-2 py-2">
        <HeaderPages title={"Hr At Glance"}></HeaderPages>
        <div className="main_back_page">
          <div className="item_div shadow px-2 my-2">
            <div className="row">
              <div className="col-12  col-md-6  col-lg-1 color-icon ">
                <p className="text-center">Employees</p>
                <div className=" h-75 d-flex align-items-center flex-column justify-content-center">
                  {" "}
                  <FaUsers size={70} />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <p className="text-center">Headcount</p>
                <div className="position-relative">
                  <ChartGradient />

                  <div className="position-absolute w-100 h-100 topRightPo d-flex align-items-center flex-column justify-content-center">
                    <span>75% </span>
                    <span className="fs-6 text-danger">
                      {" "}
                      75% <RiArrowUpSFill />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12  col-md-6  col-lg-2 ">
                <p className="text-center">Headcount by contact</p>
                <ChartDonut
                  arraylabel={[10, 2, 4, 5]}
                  arrayvalue={[1, 2, 5, 6]}
                />
              </div>
              <div className="col-12  col-md-6  col-lg-3">
                <p className="text-center">Headcount by Organization level</p>
                <ChartBar />
              </div>
              <div className="col-12 pb-4   col-lg-3     d-flex justify-content-around">
                <div>
                  <p className="text-center">Fite </p>
                  <div className="h-50 fs-3  d-flex align-items-center flex-column justify-content-center">
                    20%
                  </div>
                </div>
                <div>
                  <p className="text-center">Avarege Tenure </p>
                  <div className="h-50 fs-3  d-flex align-items-center flex-column justify-content-center">
                    20%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item_div shadow px-2 my-2">
            <div className="row">
              <div className="col-12  col-md-6  col-lg-1 color-icon ">
                <p className="text-center">Diversity</p>
                <div className=" h-75 d-flex align-items-center flex-column justify-content-center">
                  {" "}
                  <FaUsers size={70} />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <p className="text-center">%female leaders</p>
                <div className="position-relative">
                  <ChartGradient />

                  <div className="position-absolute w-100 h-100 topRightPo d-flex align-items-center flex-column justify-content-center">
                    <span>75% </span>
                    <span className="fs-6 text-danger">
                      {" "}
                      75% <RiArrowUpSFill />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12  col-md-6  col-lg-2 ">
                <p className="text-center">Headcount by Gender</p>
                <ChartDonut
                  arraylabel={[10, 2, 4, 5]}
                  arrayvalue={[1, 2, 5, 6]}
                />
              </div>
              <div className="col-12  col-md-6  col-lg-4">
                <p className="text-center">Headcount by Age Range</p>
                <ChartBar />
              </div>
              <div className="col-12 pb-4   col-lg-2     d-flex justify-content-around">
                <div>
                  <p className="text-center">average Age </p>
                  <div className="h-50 fs-3  d-flex align-items-center flex-column justify-content-center">
                    20%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item_div shadow px-2 my-2">
            <div className="row">
              <div className="col-12  col-md-6  col-lg-1 color-icon ">
                <p className="text-center">Hiring</p>
                <div className=" h-75 d-flex align-items-center flex-column justify-content-center">
                  {" "}
                  <FaUser size={70} />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <p className="text-center">Hires</p>
                <div className="position-relative">
                  <ChartGradient />

                  <div className="position-absolute w-100 h-100 topRightPo d-flex align-items-center flex-column justify-content-center">
                    <span>75% </span>
                    <span className="fs-6 text-danger">
                      {" "}
                      75% <RiArrowUpSFill />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12  col-md-6  col-lg-2 ">
                <p className="text-center">hires by contract type</p>
                <ChartDonut
                  arraylabel={[10, 2, 4, 5]}
                  arrayvalue={[1, 2, 5, 6]}
                />
              </div>
              <div className="col-12  col-md-6  col-lg-4">
                <p className="text-center">hires by Organization level</p>
                <ChartBar />
              </div>
              <div className="col-12  col-md-6  col-lg-2 ">
                <p className="text-center">hires by contract type</p>
                <ChartDonut
                  arraylabel={[10, 2, 4, 5]}
                  arrayvalue={[1, 2, 5, 6]}
                />
              </div>
            </div>
          </div>
          <div className="item_div shadow px-2 my-2">
            <div className="row">
              <div className="col-12  col-md-6  col-lg-1 color-icon ">
                <p className="text-center">Employees</p>
                <div className=" h-75 d-flex align-items-center flex-column justify-content-center">
                  {" "}
                  <FaUsers size={70} />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <p className="text-center">Headcount</p>
                <div className="position-relative">
                  <ChartGradient />

                  <div className="position-absolute w-100 h-100 topRightPo d-flex align-items-center flex-column justify-content-center">
                    <span>75% </span>
                    <span className="fs-6 text-danger">
                      {" "}
                      75% <RiArrowUpSFill />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12  col-md-6  col-lg-2 ">
                <p className="text-center">Headcount by contact</p>
                <ChartDonut
                  arraylabel={[10, 2, 4, 5]}
                  arrayvalue={[1, 2, 5, 6]}
                />
              </div>
              <div className="col-6 pb-4 col-md-4  col-lg-2     d-flex justify-content-center">
                <div>
                  <p className="text-center">Fite </p>
                  <div className="h-50  fs-3  d-flex align-items-center flex-column justify-content-center">
                    20%
                  </div>
                </div>
              </div>
              <div className="col-6 pb-4 col-md-4   col-lg-2     d-flex justify-content-center">
                <div>
                  <p className="text-center">Fite </p>
                  <div className="h-50 fs-3  d-flex align-items-center flex-column justify-content-center">
                    20%
                  </div>
                </div>
              </div>
              <div className="col-12 pb-4 col-md-4   col-lg-2     d-flex justify-content-center">
                <div>
                  <p className="text-center">Fite </p>
                  <div className="h-50 fs-3 d-flex align-items-center flex-column justify-content-center">
                    20%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item_div shadow px-2 my-2">
            <div className="row">
              <div className="col-12  col-md-6  col-lg-1 color-icon ">
                <p className="text-center">Employees</p>
                <div className=" h-75 d-flex align-items-center flex-column justify-content-center">
                  {" "}
                  <FaUsers size={70} />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <p className="text-center">Headcount</p>
                <div className="position-relative">
                  <ChartGradient />

                  <div className="position-absolute w-100 h-100 topRightPo d-flex align-items-center flex-column justify-content-center">
                    <span>75% </span>
                    <span className="fs-6 text-danger">
                      {" "}
                      75% <RiArrowUpSFill />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12  col-md-6  col-lg-2 ">
                <p className="text-center">Headcount by contact</p>
                <ChartDonut
                  arraylabel={[10, 2, 4, 5]}
                  arrayvalue={[1, 2, 5, 6]}
                />
              </div>
              <div className="col-12  col-md-6  col-lg-3">
                <p className="text-center">Headcount by Organization level</p>
                <ChartBar />
              </div>
              <div className="col-12  col-md-6  col-lg-3">
                <p className="text-center">Headcount by Organization level</p>
                <ChartBar />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
