
const initalValue = {
      InvocesTotal: [],
      InvocesTotalChart: [],
      account_totals: [],
      login: [],
      business_users: [],
      business_users_all_data: [],
      business_users_qty: [],
      business_users_locations: [],
      business_users_Totals: [],
      customer_reservations_total: [],
      specialists_services_totals: [],
      specialists_services_chart: [],
      customers_num_reservations: [],
      inventories: [],
      allInventories: [],
      products_list: [],
      All_product_list: [],
      detailsProduct: [],
      detailsProduct_withPAges: [],
      products: []

}
export const casherReducer = (state = initalValue, action) => {
      switch (action.type) {
            case "INVOCETOTAL":
                  return { ...state, InvocesTotal: action.InvocesTotal }
            case "detailsProduct_withPAges":
                  return { ...state, detailsProduct_withPAges: action.payload }
            case "detailsProduct":
                  return { ...state, detailsProduct: action.payload }
            case "inventories":
                  return { ...state, inventories: action.payload }
            case "products_list":
                  return { ...state, products_list: action.payload }
            case "All_product_list":
                  return { ...state, All_product_list: action.payload }
            case "allInventories":
                  return { ...state, allInventories: action.payload }
            case "specialists_services_totals":
                  return { ...state, specialists_services_totals: action.payload }
            case "specialists_services_chart":
                  return { ...state, specialists_services_chart: action.payload }
            case "InvocesTotalChart":
                  return { ...state, InvocesTotalChart: action.InvocesTotalChart }
            case "account_totals":
                  return { ...state, account_totals: action.account_totals }
            case "login":
                  return { ...state, login: action.login }
            case "business_users":
                  return { ...state, business_users: action.payload }
            case "business_users_all_data":
                  return { ...state, business_users_all_data: action.payload }
            case "business_users_qty":
                  return { ...state, business_users_qty: action.payload }
            case "business_users_locations":
                  return { ...state, business_users_locations: action.payload }
            case "business_users_Totals":
                  return { ...state, business_users_Totals: action.payload }
            case "customer_reservations_total":
                  return { ...state, customer_reservations_total: action.payload }
            case "customers_num_reservations":
                  return { ...state, customers_num_reservations: action.payload }
            case "products":
                  return { ...state, products: action.payload }
            default:
                  return state
      }
}
