import React from "react";

export default function FormCheckInput({ value, label, checkFilter }) {
  return (
    <div>
      <div className="form-check form-check-inline  ">
        <input
          className="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id={value}
          value={value}
          defaultChecked={checkFilter === value}
        />
        <label className="form-check-label" htmlFor={value}>
          {label}
        </label>
      </div>
    </div>
  );
}
