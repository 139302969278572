import React from "react";
import SpecialistServices from "../../component/Specialists_Services/SpecialistServices";

export default function ServicesKlar() {
  return (
    <>
      <SpecialistServices
        list_type={"services"}
        title={"Services"}
        labelTabel={"Services vs. Reservations"}
      />
    </>
  );
}
