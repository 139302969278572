import React, { forwardRef } from "react";
import "./style.css";
import HeaderPDF from "./HeaderPDF";
import FooterPDF from "./FooterPDF";
import HTabeleDetailsProduct from "../TabelComp/TabeleDetailsProduct/HTabeleDetailsProduct";
import TbodyDetailsProduct from "../TabelComp/TabeleDetailsProduct/TbodyDetailsProduct";

const PdfTabelDetailsProdect = forwardRef((props, ref) => {
  return (
    <>
      <div ref={ref}>
        <table className="table  table-striped    ">
          <HTabeleDetailsProduct orders={props.orders}>
            <HeaderPDF />
          </HTabeleDetailsProduct>

          {props.data && (
            <TbodyDetailsProduct data={props.data} orders={props.orders} />
          )}

          <tfoot>
            <FooterPDF />
          </tfoot>
        </table>
      </div>
    </>
  );
});

export default PdfTabelDetailsProdect;
