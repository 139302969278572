import React from "react";
import { useTranslation } from "react-i18next";
import { FormatNumberHook } from "../../hooks/FormatNumber";
import ButtonFullScreen from "../buttonFullScreen/ButtonFullScreen";
import ChartBarAside from "../charts/ChartBarAside";

const ChartBarAsideFullScreen = ({
  full_screen,
  NameSection,
  created_date,
  arrayvalue,
  TotalCalc,
  setfull_screen,
  title,
  name,
}) => {
  const { t, i18n } = useTranslation();
  const [langNumber] = FormatNumberHook();
  let lang = i18n.language;
  return (
    <div
      className={`shadow m-1  ${NameSection == full_screen && "full_screen"} `}
    >
      <ButtonFullScreen
        NameType={NameSection}
        full_screen={full_screen}
        setfull_screen={setfull_screen}
      />
      <h4 className="text-center"> {t(title)} </h4>
      <ChartBarAside
        title={title}
        name={name}
        valueHight={NameSection == full_screen ? "95%" : "600px"}
        created_date={created_date}
        arrayvalue={arrayvalue}
      />
      <div className="  shadow d-flex justify-content-center  align-items-center  div_number_chart">
        {" "}
        {TotalCalc && ` ${langNumber(TotalCalc)}  ${t("K")} `}{" "}
      </div>
    </div>
  );
};
export default ChartBarAsideFullScreen;
