import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assset/image/VODO_LOGO.png";
import "./style.css";
export default function AssidBar() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const secure_business = JSON.parse(sessionStorage.getItem("secure_key"));

  return (
    <>
      <div
        className={`offcanvas assidBar ${
          lang === "en" ? "offcanvas-start" : "offcanvas-end"
        }`}
        tabIndex="-1"
        id="offcanvasWithBackdrop"
        aria-labelledby="offcanvasWithBackdropLabel"
      >
        <div className="offcanvas-header      align-items-start">
          <div>
            <Link to={"/"}>
              <img width={150} src={logo} alt="" />
            </Link>
          </div>

          <button
            type="button"
            className="btn-close text-reset shadow"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body ">
          {/* <div className="accordion-item shadow rounded-4   ">
            <div
              className="accordion-button   rounded-4 p-2"
              data-bs-toggle="collapse"
              data-bs-target="#Hr"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Hr
            </div>

            <div
              id="Hr"
              className="accordion-collapse collapse "
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <ul className="nav flex-column">
                <hr className="my-0 m-auto w-75" />

                <li
                  className="nav-item  active  my-2 "
                  data-bs-dismiss="offcanvas"
                >
                  <Link
                    className="nav-link "
                    aria-current="page"
                    to={"/HRAtGlance"}
                  >
                    HR at a Glance{" "}
                  </Link>
                </li>
                <li className="nav-item   " data-bs-dismiss="offcanvas">
                  <Link className="nav-link" to={"/DemogtaphicAnalysis"}>
                    Demogtaphic Analysis
                  </Link>
                </li>
                <li className="nav-item   " data-bs-dismiss="offcanvas">
                  <Link className="nav-link" to={"/Diversity"}>
                    Diversity
                  </Link>
                </li>
                <li className="nav-item   " data-bs-dismiss="offcanvas">
                  <Link className="nav-link" to={"/EmployeeProfile"}>
                    Employee Profile
                  </Link>
                </li>
                <li className="nav-item   " data-bs-dismiss="offcanvas">
                  <Link className="nav-link" to={"HistoricalTrends"}>
                    {t("Historical Trends")}
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="accordion-item shadow rounded-4  my-2 ">
            <div
              className="accordion-button  rounded-4 p-2"
              data-bs-toggle="collapse"
              data-bs-target="#Acounting"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {t("Acounting")}
            </div>

            <div
              id="Acounting"
              className="accordion-collapse collapse "
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <ul className="nav flex-column">
                <li
                  className="nav-item  active  my-2"
                  data-bs-dismiss="offcanvas"
                >
                  <Link
                    className="nav-link "
                    aria-current="page"
                    to={"/Invoices"}
                  >
                    {t("Invoices")}
                  </Link>
                </li>
                <li className="nav-item   " data-bs-dismiss="offcanvas">
                  <Link
                    className="nav-link "
                    aria-current="page"
                    to={"/BalanceSheet"}
                  >
                    {t("Balance Sheet")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className=" my-2 " data-bs-dismiss="offcanvas">
            <Link
              className="shadow rounded-4 p-2 nav-link accordion-button"
              to={"/Customers"}
            >
              {t("Customers")}
            </Link>

            <Link
              className="shadow mt-3 rounded-4 p-2 nav-link accordion-button"
              to={"/Suppliers"}
            >
              {t("Suppliers")}
            </Link>
            <Link
              className="shadow mt-3 rounded-4 p-2 nav-link accordion-button"
              to={"/Inventories"}
            >
              {t("Inventories")}
            </Link>
            <Link
              className="shadow mt-3 rounded-4 p-2 nav-link accordion-button"
              to={"/DetailsProduct"}
            >
              {t("Goods")}
            </Link>
          </div>

          {secure_business === "574526399db2b55b5a7.88351406" && (
            <div className="accordion-item shadow rounded-4  my-2 ">
              <div
                className="accordion-button  rounded-4 p-2"
                data-bs-toggle="collapse"
                data-bs-target="#Klar"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Klar
              </div>

              <div
                id="Klar"
                className="accordion-collapse collapse "
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <ul className="nav flex-column">
                  <li
                    className="nav-item  active  my-2"
                    data-bs-dismiss="offcanvas"
                  >
                    <Link
                      className="nav-link "
                      aria-current="page"
                      to={"/Reservations"}
                    >
                      {t("Reservations")}
                    </Link>
                  </li>
                  <li
                    className="nav-item  active  my-2"
                    data-bs-dismiss="offcanvas"
                  >
                    <Link
                      className="nav-link "
                      aria-current="page"
                      to={"/Services"}
                    >
                      {t("Services")}
                    </Link>
                  </li>
                  <li
                    className="nav-item  active  my-2"
                    data-bs-dismiss="offcanvas"
                  >
                    <Link
                      className="nav-link "
                      aria-current="page"
                      to={"/Specialists"}
                    >
                      {t("Specialists")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>{" "}
    </>
  );
}
