import React, { forwardRef } from "react";
import "./style.css";
import HeaderPDF from "./HeaderPDF";
import FooterPDF from "./FooterPDF";
import Tbody from "../TabelComp/TabelelSpecialistServices/Tbody";
import HeadTabel from "../TabelComp/TabelelSpecialistServices/HeadTabel";

const PdfTabelSpecialistServices = forwardRef((props, ref) => {
  return (
    <>
      <div ref={ref}>
        <table className="table  table-striped    ">
          <HeadTabel orders={props.orders}>
            <HeaderPDF />
          </HeadTabel>

          {props.data && <Tbody data={props.data} orders={props.orders} />}

          <tfoot>
            <FooterPDF />
          </tfoot>
        </table>
      </div>
    </>
  );
});

export default PdfTabelSpecialistServices;
