import React from "react";
import { FormatDataHook } from "../../../hooks/formatDataHook";
import { useNavigate } from "react-router";

import { FiExternalLink } from "react-icons/fi";
import "../style.css";
export default function TbodyInventories({ data, orders }) {
  const [formatWithOutNumber] = FormatDataHook(false);
  const navgite = useNavigate();

  return (
    <>
      <tbody>
        {data &&
          data.map((item) => (
            <tr className="pointer" key={item.id}>
              <td>{item.id}</td>
              <td> {item.name}</td>
              <td>{item.address}</td>
              <td>{formatWithOutNumber(item.created_at)}</td>
              <td>{item.keeper?.name}</td>
              <td>{item.keeper?.mobile}</td>
              <td>
                <button
                  className="btn Activ_btn_action"
                  onClick={() => navgite(`/Inventories/${item.id}`)}
                >
                  <FiExternalLink size={22} />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </>
  );
}
