import { t } from "i18next";
import React from "react";

export default function Top_Less_Limit({
  filter_Top_Less_user,
  setfilter_Top_Less_user,
  Limit_Top_Less_user,
  setLimit_Top_Less_user,
  all,
  less,
  top,
}) {
  return (
    <>
      {" "}
      <div
        className="btn-group my-2  d-flex   justify-content-center"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <form action="" className="btn-group d-flex flex-wrap">
          <input
            type="radio"
            className="btn-check"
            name="All_users"
            id="All_users"
            value={"all"}
            onChange={(e) => setfilter_Top_Less_user(e.target.value)}
            checked={filter_Top_Less_user === "all"}
          />
          <label
            className="btn btn-outline-danger rounded-0"
            htmlFor="All_users"
          >
            {t(all)}
          </label>

          <input
            type="radio"
            className="btn-check"
            name="top_user"
            id="top_user"
            value={"top"}
            onChange={(e) => setfilter_Top_Less_user(e.target.value)}
            checked={filter_Top_Less_user === "top"}
          />
          <label className="btn btn-outline-danger" htmlFor="top_user">
            {t(top)}
          </label>

          <input
            type="radio"
            className="btn-check"
            name="less_user"
            id="less_user"
            value={"less"}
            onChange={(e) => setfilter_Top_Less_user(e.target.value)}
            checked={filter_Top_Less_user === "less"}
          />
          <label className="btn btn-outline-danger" htmlFor="less_user">
            {t(less)}
          </label>
        </form>
        <input
          onChange={(e) => setLimit_Top_Less_user(e.target.value)}
          value={Limit_Top_Less_user}
          type="number"
          className="border border-danger      text-center    w-25 "
        />
      </div>
    </>
  );
}
