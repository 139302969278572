import React from 'react'
import { useTranslation } from 'react-i18next';

export const FormatNumberHook = () => {
    const { t, i18n } = useTranslation();
    //    let lang =i18n.language
    let lang = "en"

    const langNumber = (data) => {
        return data.toLocaleString(`${lang}-EG`);
    }

    return [langNumber]
}
