import { t } from 'i18next';
import React, { useState } from 'react'
 import { FaFilter } from "react-icons/fa";
import { FormatDataMonthHook } from '../../hooks/formatDataHook';
import FilterTime from '../filterTime/FilterTime';
import "./style.css"
export default function HeaderPages({HendelSelect,setMonth,setYear,year,typeData,month,title,children,Day,setDay,type,setType}) {
    const [active,setActive]=useState(false)
    const [formatMonth ] = FormatDataMonthHook()
    const NowMonth = formatMonth(new Date())
  return (
    <>
        <div className="d-flex align-items-center justify-content-between">
         <div className=""> 
        <h2 className="title_Page_border">{t(title)}</h2>
        </div>
        <div className=" ">
       <button onClick={() => setActive(!active)} className={`${active && "activeIconFilter"} accordion-button collapsed`} type="button" data-bs-toggle="collapse" data-bs-target="#FilterTime_collapse" aria-expanded="false" aria-controls="flush-collapseOne">
          <FaFilter color="#ea3e36"  size={28}/>
       </button>
        </div>
        </div>
 
       
        <div id="FilterTime_collapse" className="py-2 accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
       <FilterTime type={type} setType={setType} Day={Day} setDay={setDay} month={month} typeData={typeData} HendelSelectInvoce={HendelSelect} setMonth={setMonth} setYear={setYear}  year={year}/>
       
       {children}

       </div>
     
    </>
  )
}
