import React from "react";
import { useTranslation } from "react-i18next";

export default function LiInputRadio({
  value,
  nameID,
  name,
  labelName,
  activ,
}) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <li>
        <input
          checked={value == activ}
          onChange={(e) => {}}
          value={value}
          type="radio"
          id={nameID}
          name={name}
        />
        <label htmlFor={nameID}> {t(labelName)} </label>
      </li>
    </>
  );
}
