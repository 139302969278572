import React from "react";
import Chart from "react-apexcharts";

export default function ChartDonut({
  valueHight,
  arraylabel,
  arrayvalue,
  staticColor,
}) {
  const generateColors = () => {
    const colors = [];
    const colorCount = arraylabel && arraylabel.length;

    for (let i = 0; i < colorCount; i++) {
      const hue = (i * 255) / colorCount;
      const color = `hsl(${hue}, 70%, 50%)`;
      colors.push(color);
    }

    return colors;
  };
  const filterDataChart = (data) => {};
  const options = {
    series: arrayvalue,
    plotOptions: {},
    chartOptions: {
      chart: {
        events: {
          click: function (event, chartContext, config) {
            filterDataChart(config.dataPointIndex);
          },
        },
      },
      labels: arraylabel,
      colors: staticColor ? ["#E83C33", "#ff8d87"] : generateColors(),
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
    },
  };
  return (
    <>
      <Chart
        options={options.chartOptions}
        type="pie"
        series={options.series}
        width="100%"
        height={valueHight ? valueHight : 200}
      />
    </>
  );
}
