import React from "react";

import { BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import "./style.css";
class PdfTabelDelegateDetails extends React.Component {
  render() {
    return (
      <>
        <div>
          <table className="table table-hover  table-striped    ">
            <tbody>
              {this.props.data &&
                this.props.data.data &&
                this.props.data.data.journals.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td> {item.tax_total.toFixed(2)}</td>
                    <td>{item.sub_total.toFixed(2)}</td>
                    <td>{item.final_total.toFixed(2)}</td>
                    <td>{item.type}</td>
                    <td>{item.created_date}</td>
                    <td>{item.migration_datetime}</td>
                    <td>{item.created_at}</td>
                    <td>{item.updated_at}</td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={9}>
                  <div className="row footer_Tabel_bdf py-2">
                    <div className="col-4">800 124 8000 | saib.com.sa</div>
                    <div className="col-4 text-center">notes</div>
                    <div className="col-4 ">
                      <span className="m-1">
                        <BsFacebook size={25} />
                      </span>
                      <span className="m-1">
                        <AiFillTwitterCircle size={25} />
                      </span>
                      <span className="m-1">
                        <AiFillInstagram size={25} />
                      </span>
                      <span className="m-1"> info@Vodo.com</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </>
    );
  }
}

export default PdfTabelDelegateDetails;
